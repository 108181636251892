import { useTheme } from "@mui/material";
import { VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import { useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import getReactJoyrideStyleOverridesObject from "../../styles/getReactJoyrideStyleOverridesObject";
import { MEDIA_PAGE_TUTORIAL_DONE } from "../../utils/joyRideTutorialsLocalStorageStrings";

interface IProps {
    runJoyrideMediaPage: boolean;
    setRunJoyride: (runJoyrideOverview: boolean) => void;
}

export default function MediaPageJoyride({ runJoyrideMediaPage, setRunJoyride }: IProps) {
    const [steps] = useState<Array<Step>>([
       
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Media pages</VTTypography>
                    <VTTypography type={"Paragraph"}>This is where you can view and manage your media</VTTypography>
                </>
            ),
            placement: "center",
            target: "body",
            disableBeacon: true,
        },
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Media</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        Your media can be rename and otherwise managed here, to rename please click on a media items
                        title
                    </VTTypography>
                </>
            ),

            spotlightPadding: 10,
            target: ".paper",
            disableBeacon: true,
        },
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Upload</VTTypography>
                    <VTTypography type={"Paragraph"}>To upload local images please click here</VTTypography>
                </>
            ),

            spotlightPadding: 10,
            target: ".uploadButton",
            disableBeacon: true,
            spotlightClicks: true,
        },
    ]);

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setRunJoyride(false);

            localStorage.setItem(MEDIA_PAGE_TUTORIAL_DONE, "true");
        }
    };

    const theme = useTheme();
    const joyrideThemeOverridesObj = getReactJoyrideStyleOverridesObject(theme.palette.primary.main);

    return (
        <>
            <Joyride
                callback={handleJoyrideCallback}
                continuous
                run={runJoyrideMediaPage}
                scrollToFirstStep
                showProgress
                showSkipButton
                styles={joyrideThemeOverridesObj}
                steps={steps}
                disableScrolling
            />
        </>
    );
}
