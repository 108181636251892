//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IGroup, IUser } from "../models/user.model";
import { IAPIResponse } from "@virtus-tech-repository/virtus-tech-repository/lib/models/api.model";

// Custom components imports (and hooks and helpers)

export const UserApi = createApi({
    reducerPath: "user",
    tagTypes: ["users", "groups"],
    baseQuery: fetchBaseQuery({
        // baseUrl: process.env.REACT_APP_IS_LOCAL
        //   ? `${process.env.PUBLIC_URL}/static`
        //   : process.env.REACT_APP_SCENARIO_API,
        // baseUrl: process.env.REACT_APP_ACCOUNTS_API,
    }),
    endpoints: (builder) => ({
        getAllUsers: builder.query<IUser[], void>({
            query: () => ({
                //url: process.env.REACT_APP_IS_LOCAL ? "users.json" : "users",
                url: `${process.env.REACT_APP_ACCOUNTS_API}/users/5390ce91-dde4-430c-8578-02cbf04eb77f`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IUser) => ({
                              type: "users" as const,
                              id,
                          })),
                          { type: "users", id: "LIST" },
                      ]
                    : [{ type: "users", id: "LIST" }],
        }),
        getAllGroups: builder.query<IGroup[], string>({
            query: (userId) => ({
                url: `${process.env.REACT_APP_ACCOUNTS_API}/groups/${userId}`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IGroup) => ({
                              type: "groups" as const,
                              id,
                          })),
                          { type: "groups", id: "LIST" },
                      ]
                    : [{ type: "groups", id: "LIST" }],
        }),

        getUserCode: builder.query<{ code: string }, string>({
            query: (userId) => ({
                url: `${process.env.REACT_APP_ACCESS_API}/access`,
                method: "POST",
                body: { email: userId },
            }),
            keepUnusedDataFor: 0.1,
        }),
    }),
});

export const { useGetAllUsersQuery, useGetAllGroupsQuery, useGetUserCodeQuery } = UserApi;
