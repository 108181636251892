import { SvgIcon, SxProps } from "@mui/material";
import { Background } from "@xyflow/react";

interface IIconProps{
    sx?: SxProps
}
export default function IconPrecision({sx}: IIconProps) {
    return (
        <SvgIcon viewBox="0 -960 960 960" sx={{ ...sx }}>
            <path d="M220.92-150.77q-19.23 0-32.69-13.46-13.46-13.46-13.46-32.69 0-19.23 13.46-32.7 13.46-13.46 32.69-13.46h76.31L194.46-579.39q-26.61-13.84-43.15-40.15t-16.54-58.15q0-45.77 32.12-77.89 32.11-32.11 77.88-32.11 37.85 0 66.23 22.5 28.38 22.5 38.85 57.5h137.23v-50q0-12.77 8.61-21.39 8.62-8.61 21.39-8.61 7.84 0 14.61 4t10.46 11.23v5.38l70.31-65.53q8.62-8.62 19.96-10.73 11.35-2.12 22.19 3.5l153.7 71.23q9.69 4.84 13.8 14.03 4.12 9.2-.11 18.27-4.85 9.7-14.04 12.43-9.19 2.73-18.27-1.5l-147.84-68.31-94.77 88v56l94.77 86L789.69-632q9.08-4.23 18.58-1.19 9.5 3.04 13.73 12.11 4.84 9.69.42 18.58-4.42 8.88-14.11 13.73l-153.7 72.46q-10.84 5.62-22.19 3.5-11.34-2.11-19.96-10.73l-70.31-64.77v5.39q-3.69 6-10.46 10.61-6.77 4.62-14.61 4.62-12.77 0-21.39-8.62-8.61-8.61-8.61-21.38v-50H349.85q-3 10.69-8.23 20.77-5.24 10.07-12.39 18.46l196.92 365.38h102.47q19.23 0 32.69 13.46 13.46 13.47 13.46 32.7 0 19.23-13.46 32.69-13.46 13.46-32.69 13.46h-407.7Zm23.85-476.92q20.85 0 35.42-14.58 14.58-14.58 14.58-35.42 0-20.85-14.58-35.43-14.57-14.57-35.42-14.57t-35.42 14.57q-14.58 14.58-14.58 35.43 0 20.84 14.58 35.42 14.57 14.58 35.42 14.58Z" />{" "}
        </SvgIcon>
    );
}
