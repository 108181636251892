import { Styles } from "react-joyride";

export default (mainThemeColour: string): Partial<Styles> => {
    return {
        options: {
            arrowColor: "#454545",
            backgroundColor: "#454545",
            primaryColor: mainThemeColour,
            textColor: "#eee",
            zIndex: 10000,
        },
        buttonBack: {
            color: "#bbb",
        },
        buttonSkip: {
            color: "#bbb",
        },
        buttonClose: {
            display: "none",
        },
    };
};
