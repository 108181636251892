//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports

import { Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { textCutOffAddElipsis, VTButton } from "@virtus-tech-repository/virtus-tech-repository";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { useGetScenesQuery } from "../../services/scene.service";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Params, useNavigate, useParams } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useEffect, useState } from "react";
import { useGetGroupHotspotsQuery } from "../../services/hotspot.service";
import VTVideoTimeline from "./VTVideoTimeline";
import VTTimelineItem from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTTimelineItem";
import { getImageForSceneOrDefaultImageIfPreviewImageDoesntExist } from "../../utils/getImageForSceneOrDefaultImageIfPreviewImageDoesntExist";
import { getTimeElapsedInStopWatchFormatString } from "../../utils/getTimeElapsedInStopWatchFormattedString";

// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sortedQuestions: IHotspot[];
    videoNextQuestion: IHotspot | undefined;
    questionElement: IHotspot;
    index: number;
    handleTimelineItemClicked: (time: number) => void;
    videoCurrentState: string;
    idOfVideoQuestionPreviousToNext: string | undefined;
    timeOfCurrentlySelectedQuestion: number | undefined;
    disableMainButtonGlow?: boolean;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function VTVideoTimelineElement({
    sortedQuestions,
    videoNextQuestion,
    questionElement,
    index,
    handleTimelineItemClicked,
    videoCurrentState,
    idOfVideoQuestionPreviousToNext,
    timeOfCurrentlySelectedQuestion,
    disableMainButtonGlow,
}: IProps) {
    console.log("video next question", videoNextQuestion);
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { scenarioId }: Readonly<Params<string>> = useParams();
    const theme = useTheme();

    // const { data: answers } = useGetGroupHotspotsQuery(questionElement.id);
    const { data: scenes } = useGetScenesQuery(scenarioId ? scenarioId : "");
    const navigate = useNavigate();

    // TODO: <Grid /> usage in this component should be replaced with <Box component="div"/>, grid is useful for layout that scales with screen-size (normally width) -
    // whereas here the width of the component is fixed (89px), so grid doesn't make sense to be used here
    return (
        <Grid
            item
            container
            sx={{
                height: "100%",
                minWidth: "89px",
                width: "89px",
                // background: "blue",
                // position: "absolute",
                // background: "blue"
            }}
            alignContent={"flex-start"}
            justifyContent={"flex-start"}
        >
            <Tooltip title={questionElement.contents.preClickContent.title}>
                <Grid item sx={{ position: "relative" }}>
                    <VTButton
                        type={"icon"}
                        sx={{
                            width: "40px",
                            height: "40px",
                            fontSize: "0.9em",

                            background: `linear-gradient(to bottom, ${theme.palette.info.light},${theme.palette.info.main})`,
                            // transition: ".5s",

                            boxShadow:
                                // questionElement.contents.preClickContent.question!.time! ===
                                //     timeOfCurrentlySelectedQuestion &&
                                !disableMainButtonGlow &&
                                !(idOfVideoQuestionPreviousToNext === questionElement.id) &&
                                videoNextQuestion &&
                                videoNextQuestion.id === questionElement.id &&
                                videoCurrentState !== "Playing" &&
                                videoCurrentState !== "Paused"
                                    ? "0 0 25px #fff"
                                    : "none",
                            ":hover": {
                                boxShadow: "0 0 25px #fff",
                                background: `linear-gradient(to bottom, ${theme.palette.info.light},${theme.palette.info.main})`,
                            },
                        }}
                        onClick={() => {
                            handleTimelineItemClicked(questionElement.contents.preClickContent.question!.time!);
                        }}
                    >
                        {`${index + 1}`}
                    </VTButton>{" "}
                    <Typography
                        align="center"
                        sx={{
                            fontSize: "0.6em",
                            position: "absolute",
                            left: "39px",
                            top: "-7px",
                        }}
                    >
                        {getTimeElapsedInStopWatchFormatString(
                            questionElement.contents.preClickContent.question!.time! * 1000,
                        )}{" "}
                    </Typography>
                </Grid>
            </Tooltip>

            {index !== sortedQuestions.length - 1 && (
                <Grid item>
                    <hr
                        style={{
                            color: "#fff",
                            backgroundColor: "#fff",
                            height: "3px",
                            width: "40px",
                            borderRadius: "40%",
                            marginTop: "18.5px",
                            marginLeft: "5px",
                            ...(idOfVideoQuestionPreviousToNext === questionElement.id &&
                                videoCurrentState !== "Question" && {
                                    boxShadow: "0px 0px 10px 4px #fff",
                                }),
                        }}
                    />
                </Grid>
            )}

            {questionElement &&
                questionElement.groupedHotspots &&
                questionElement.groupedHotspots.map((answerElement: IHotspot, i) => {
                    if (
                        scenes &&
                        answerElement.actions?.movement?.sceneId &&
                        answerElement.actions?.movement?.sceneId !== "Scene Undefined"
                    ) {
                        const branchScene: IScene | undefined = scenes.find(
                            (findSceneElement: IScene) =>
                                findSceneElement.id === answerElement.actions?.movement?.sceneId,
                        );

                        const isLastBranchingAnswerForQuestion = questionElement.groupedHotspots!.length - 1 === i;

                        if (branchScene) {
                            return (
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    sx={{
                                        position: "relative",
                                        // border: "2px solid white",
                                        ...(isLastBranchingAnswerForQuestion && {
                                            paddingBottom: "30px",
                                        }),
                                    }}
                                    direction={"column"}
                                    alignContent={"flex-start"}
                                >
                                    <Typography
                                        align="center"
                                        sx={{
                                            fontSize: "0.5em",
                                            position: "absolute",
                                            left: "40px",
                                            top: "37px",
                                        }}
                                    >
                                        {textCutOffAddElipsis(branchScene?.name.toLowerCase(), 6)}
                                    </Typography>
                                    <Grid item>
                                        <hr
                                            style={{
                                                color: "#fff",
                                                backgroundColor: "#fff",
                                                height: "20px",
                                                width: "3px",
                                                borderRadius: "40%",
                                                marginLeft: "18.5px",
                                                marginTop: "3px",
                                                // border: "2px solid lime",
                                                // border: "5px solid green"
                                                // boxShadow: "0 0 55px green",
                                            }}
                                        />
                                    </Grid>
                                    <Tooltip
                                        // open
                                        // sx={{ background: "blue" }}
                                        title={
                                            <div
                                                style={{
                                                    margin: "-13px",
                                                }}
                                            >
                                                <VTTimelineItem
                                                    onClicked={() => {
                                                        navigate(
                                                            `/creator/${scenarioId}/${answerElement.actions?.movement?.sceneId}`,
                                                        );
                                                    }}
                                                    mediaType={branchScene.mediaType}
                                                    disableHover
                                                    image={getImageForSceneOrDefaultImageIfPreviewImageDoesntExist(
                                                        branchScene,
                                                    )}
                                                    name={branchScene.name}
                                                />
                                            </div>

                                            // <div
                                            //     style={{
                                            //         margin: "-8px",
                                            //     }}
                                            // >
                                            //     <img
                                            //         src={
                                            //             branchScene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO ||
                                            //             branchScene.mediaType === EDraggableOptions.VIDEO
                                            //                 ? branchScene.src.split(".mp4")[0] + "_preview.png"
                                            //                 : `${branchScene?.src}/f.jpg`
                                            //         }
                                            //         height={"100px"}
                                            //         width={"150px"}
                                            //         style={{
                                            //             borderRadius: "15px 15px 0px 0px",
                                            //         }}
                                            //         alt=" "
                                            //     />
                                            //     <Typography
                                            //         align="center"
                                            //         sx={{
                                            //             marginBottom: "10px",
                                            //         }}
                                            //     >
                                            //         {branchScene?.name}
                                            //     </Typography>
                                            // </div>
                                        }
                                    >
                                        <Grid item>
                                            <VTButton
                                                type={"icon"}
                                                sx={{
                                                    width: "30px",
                                                    height: "30px",
                                                    background: `linear-gradient(to bottom, ${theme.palette.info.light},${theme.palette.info.main})`,
                                                    // transition: ".5s",
                                                    ":hover": {
                                                        boxShadow: "0 0 25px #fff",
                                                    },
                                                    marginTop: "-2px",
                                                    marginLeft: "5px",
                                                }}
                                                onClick={() => {
                                                    navigate(
                                                        `/creator/${scenarioId}/${answerElement.actions?.movement?.sceneId}`,
                                                    );
                                                }}
                                            >
                                                <KeyboardArrowRightIcon />
                                            </VTButton>
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            );
                        }
                    }
                })}
        </Grid>
    );
}
