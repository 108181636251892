import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPreviouslyLazyLoadedMediaProps {
    creatorComponentFirstMountedDate: null | Date;
    sidePanelMedia: {
        images360: { prevPagesLoaded: number };
        videos360: { prevPagesLoaded: number };
        audio: { prevPagesLoaded: number };
        videos2d: { prevPagesLoaded: number };
        images2d: { prevPagesLoaded: number };
    };
}

const previousLazyLoadedMediaState: IPreviouslyLazyLoadedMediaProps = {
    creatorComponentFirstMountedDate: null,
    sidePanelMedia: {
        images360: {
            prevPagesLoaded: 0,
        },
        videos360: {
            prevPagesLoaded: 0,
        },
        audio: {
            prevPagesLoaded: 0,
        },
        videos2d: {
            prevPagesLoaded: 0,
        },
        images2d: {
            prevPagesLoaded: 0,
        },
    },
};

const previousLazyLoadedMediaReducer = createSlice({
    name: "previousLazyLoadedMediaReducer",
    initialState: previousLazyLoadedMediaState,
    reducers: {
        setImages360PrevPagesLoaded: (state: IPreviouslyLazyLoadedMediaProps, { payload }: PayloadAction<number>) => {
            state.sidePanelMedia.images360.prevPagesLoaded = payload;
        },
        setCreatorComponentFirstMountedDate: (
            state: IPreviouslyLazyLoadedMediaProps,
            { payload }: PayloadAction<Date>,
        ) => {
            state.creatorComponentFirstMountedDate = payload;
        },

        setImages360: (state: IPreviouslyLazyLoadedMediaProps, { payload }: PayloadAction<number>) => {
            state.sidePanelMedia.images360.prevPagesLoaded = payload;
        },
        setVideos360PrevPagesLoaded: (state: IPreviouslyLazyLoadedMediaProps, { payload }: PayloadAction<number>) => {
            state.sidePanelMedia.videos360.prevPagesLoaded = payload;
        },
        setAudioPrevPagesLoaded: (state: IPreviouslyLazyLoadedMediaProps, { payload }: PayloadAction<number>) => {
            state.sidePanelMedia.audio.prevPagesLoaded = payload;
        },
        setVideos2dPrevPagesLoaded: (state: IPreviouslyLazyLoadedMediaProps, { payload }: PayloadAction<number>) => {
            state.sidePanelMedia.videos2d.prevPagesLoaded = payload;
        },
        setImages2dPrevPagesLoaded: (state: IPreviouslyLazyLoadedMediaProps, { payload }: PayloadAction<number>) => {
            state.sidePanelMedia.images2d.prevPagesLoaded = payload;
        },
    },
});

export const {
    setImages360PrevPagesLoaded,
    setVideos360PrevPagesLoaded,
    setAudioPrevPagesLoaded,
    setVideos2dPrevPagesLoaded,
    setImages2dPrevPagesLoaded,
    setCreatorComponentFirstMountedDate,
} = previousLazyLoadedMediaReducer.actions;

export default previousLazyLoadedMediaReducer.reducer;
