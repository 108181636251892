import { useEffect, useRef, useState } from "react";
import {
    VTButton,
    VTCard,
    VTMenu,
    VTTextField,
    VTTypography,
    useGetMuiBreakPointLabel,
    useGetVTCardTitleCutOffPoint,
} from "@virtus-tech-repository/virtus-tech-repository";
import { Breakpoint, CircularProgress, Grid, LinearProgress, Typography, useTheme } from "@mui/material";

import { SearchRounded } from "@mui/icons-material";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import NoScenarios from "../../assets/images/NoScenarios.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { v4 as uuid } from "uuid";

import MyLocationIcon from "@mui/icons-material/MyLocation";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

import {
    useCreateAllImmersiveImagesMutation,
    usePreSignedUrlMutation,
    useUpdateAllImmersiveImagesMutation,
} from "../../services/media.service";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import VTLocation from "../components/VTLocation";
import { setCurrentToast } from "../../store/slices/current.slice";
import { ExposedVTCardRefMethodsObject } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCard";
import VTMediaUploader from "../components/VTMediaUploader";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import MediaPageJoyride from "../components/MediaPageJoyride";
import { MEDIA_PAGE_TUTORIAL_DONE } from "../../utils/joyRideTutorialsLocalStorageStrings";

interface IProps {
    images360: IMediaItem[] | undefined;
    leftDrawWidth: number;
    deleteMediaItem: (mediaType: EDraggableOptions, collectionId: string, mediaId: string) => void;
    deleteLoading: boolean;
    loading: boolean;
}

export default function Scenario360ImageCardsContainer({
    images360,
    leftDrawWidth,
    deleteMediaItem,
    deleteLoading,
    loading,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [vtCardRef, titleCutOffPoint] = useGetVTCardTitleCutOffPoint<HTMLDivElement, ExposedVTCardRefMethodsObject>();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();
    const [createAllImmersiveImages] = useCreateAllImmersiveImagesMutation();
    const [updateAllImmersiveImages, { isLoading: immersiveImageUpdateLoading }] =
        useUpdateAllImmersiveImagesMutation();
    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [selectedImageError, setSelectedImageError] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>("");
    const [imageId, setImageId] = useState<string>(uuid());
    const [changedItem, setChangedItem] = useState<string>("");
    const [filterValue, setFilterValue] = useState<string>("");
    const [nameBoxChecked, setNameBoxChecked] = useState<boolean>(true);
    const [tagsBoxChecked, setTagsBoxChecked] = useState<boolean>(false);
    const whiteListedIds = ["e4563751-7daa-4e76-999d-af1004a0e683"];

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        setRunJoyride(
            localStorage.getItem(MEDIA_PAGE_TUTORIAL_DONE) && localStorage.getItem(MEDIA_PAGE_TUTORIAL_DONE) === "true"
                ? false
                : true,
        );
    }, []);

    useEffect(() => {
        if (mediaUploadCompleted && selectedImage) {
            createAllImmersiveImages({
                id: userId,
                media_id: imageId,
                media_type: "three_sixty_image",
                filetype: "jpg",
                name: imageName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: "",
                chopping: "processing",
            });
        }
    }, [mediaUploadCompleted]);

    useEffect(() => {
        if (!deleteLoading) {
            setChangedItem("");
        }
    }, [deleteLoading]);

    const currentScreenWidthBreakpoint = useGetMuiBreakPointLabel();
    const breakPointsToHideUploadTextOn: Breakpoint[] = ["md", "sm", "xs"];

    const [runJoyride, setRunJoyride] = useState<boolean>(false);

    return (
        <>
            {organisation === "virtus-tech" && (
                <MediaPageJoyride runJoyrideMediaPage={runJoyride} setRunJoyride={setRunJoyride} />
            )}
            <Grid container xs={12} alignItems={"flex-start"}>
                <Grid
                    item
                    container
                    xs={12}
                    spacing={1}
                    sx={{
                        margin: 5,
                        marginTop: "110px",
                        marginLeft: leftDrawWidth / 8 + 5,
                        // transition: "0.5s",
                    }}
                >
                    <Grid container xs={12} item sx={{ marginBottom: "25px" }} justifyContent={"space-between"}>
                        {activeButton === 0 && (
                            <Grid item xs={6}>
                                <VTTextField
                                    value={filterValue}
                                    onChange={(text: string) => setFilterValue(text)}
                                    placeholder="Search 360 Images"
                                    endIcon={<SearchRounded />}
                                    sx={{ width: "100%", maxWidth: "350px" }}
                                />
                            </Grid>
                        )}
                        {activeButton === 0 && (
                            <Grid container item xs={activeButton === 0 ? 6 : 12} justifyContent={"end"}>
                                <VTButton
                                    onClick={() => {
                                        if (activeButton === 0) {
                                            setRunJoyride(false);
                                            setActiveButton(1);
                                        } else {
                                            setActiveButton(0);
                                        }
                                    }}
                                    sx={{
                                        borderBottom: `3px solid ${theme.palette.primary.main}`,
                                        maxWidth: "100%",
                                        height: "60px",
                                    }}
                                    type="underline"
                                    endIcon={
                                        activeButton === 0 ? (
                                            <UploadIcon sx={{ marginLeft: "10px" }} />
                                        ) : (
                                            <KeyboardReturnIcon sx={{ marginLeft: "10px" }} />
                                        )
                                    }
                                    className={"uploadButton"}
                                >
                                    {!breakPointsToHideUploadTextOn.includes(currentScreenWidthBreakpoint) && (
                                        <Typography>Upload 360 Images </Typography>
                                    )}
                                </VTButton>
                            </Grid>
                        )}
                    </Grid>

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* Card display */}
                    {activeButton === 0 && (
                        <>
                            {images360 && images360.length === 0 ? (
                                <Grid item xs={12} className={"paper"} textAlign={"center"} sx={{ marginTop: "50px" }}>
                                    <img src={NoScenarios} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first 360 image file (.png, .jpg and .jpeg formats)
                                    </VTTypography>
                                </Grid>
                            ) : loading ? (
                                <Grid
                                    sx={{}}
                                    item
                                    container
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    className={"paper"}
                                >
                                    <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                        <VTCard
                                            sx={{ maxWidth: "100%" }}
                                            mediaType={{ imageUrl: "" }}
                                            title={""}
                                            loading={true}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                        <VTCard
                                            sx={{ maxWidth: "100%" }}
                                            mediaType={{ imageUrl: "" }}
                                            title={""}
                                            loading={true}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                        <VTCard
                                            sx={{ maxWidth: "100%" }}
                                            mediaType={{ imageUrl: "" }}
                                            title={""}
                                            loading={true}
                                        />
                                    </Grid>

                                    {(currentScreenWidthBreakpoint === "xl" ||
                                        currentScreenWidthBreakpoint === "md") && (
                                        <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                            <VTCard
                                                sx={{ maxWidth: "100%" }}
                                                mediaType={{ imageUrl: "" }}
                                                title={""}
                                                loading={true}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            ) : (
                                <Grid
                                    sx={{}}
                                    item
                                    container
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    className={"paper"}
                                >
                                    {images360 &&
                                        images360
                                            .filter((image360: IMediaItem) => {
                                                return (
                                                    (nameBoxChecked &&
                                                        image360.name
                                                            ?.toLowerCase()
                                                            .includes(filterValue.toLowerCase())) ||
                                                    (tagsBoxChecked &&
                                                        image360.tags &&
                                                        image360.tags.filter((tag: string) => {
                                                            return tag
                                                                .toLowerCase()
                                                                .includes(filterValue.toLowerCase());
                                                        }).length > 0)
                                                );
                                            })
                                            .sort((a: IMediaItem, b: IMediaItem) => {
                                                return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                            })
                                            // .filter((image360) => image360?.chopping !== "error")
                                            .map((image360, i) => (
                                                <Grid
                                                    key={image360.id}
                                                    sm={12}
                                                    md={6}
                                                    lg={4}
                                                    xl={3}
                                                    xs={12}
                                                    item
                                                    container
                                                    justifyContent={"center"}
                                                >
                                                    {(deleteLoading || immersiveImageUpdateLoading) &&
                                                    changedItem === image360.id ? (
                                                        <VTCard
                                                            sx={{ maxWidth: "100%", width: "100%" }}
                                                            title={!image360.name ? "no name" : image360.name}
                                                            loading={true}
                                                        />
                                                    ) : (
                                                        <VTCard
                                                            ref={i === 0 ? vtCardRef : undefined}
                                                            titleCutOffPoint={titleCutOffPoint}
                                                            imageIsBeingProcessedMsg={
                                                                image360.chopping === "processing"
                                                                    ? "360 image is being processed, please wait and refresh the page in a minute or so"
                                                                    : undefined
                                                            }
                                                            imageProcessingErrorMsg={
                                                                image360.chopping === "error"
                                                                    ? "there was an error processing this 360 image, please delete and try again or contact virtus tech support"
                                                                    : undefined
                                                            }
                                                            onTitleEdited={(editedTitle: string) => {
                                                                if (whiteListedIds.includes(image360.id)) {
                                                                    dispatch(
                                                                        setCurrentToast({
                                                                            id: "EditDefaultMedia",
                                                                            message:
                                                                                "You can not edit default media. Please upload your own media.",
                                                                        }),
                                                                    );
                                                                } else {
                                                                    setChangedItem(image360.id);
                                                                    updateAllImmersiveImages({
                                                                        id: image360.id,
                                                                        name: editedTitle,
                                                                        media_type: EDraggableOptions.IMMERSIVE_IMAGE,
                                                                    });
                                                                }
                                                            }}
                                                            key={image360.id}
                                                            sx={{ width: "100%", maxWidth: "none" }}
                                                            title={!image360.name ? "no name" : image360.name}
                                                            mediaType={{
                                                                showPreview: true,
                                                                preview: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${image360.id}/${image360.id}_preview.jpg`,
                                                                textures: {
                                                                    backTextureUrl: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${image360.id}/b.jpg`,
                                                                    frontTextureUrl: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${image360.id}/f.jpg`,
                                                                    leftTextureUrl: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${image360.id}/l.jpg`,
                                                                    rightTextureUrl: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${image360.id}/r.jpg`,
                                                                    upTextureUrl: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${image360.id}/u.jpg`,
                                                                    downTextureUrl: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${image360.id}/d.jpg`,
                                                                },
                                                            }}
                                                            onWholeCardClicked={() => {}}
                                                            menuOptions={{
                                                                menuOptionsList: [
                                                                    // {
                                                                    //     component: (
                                                                    //         <VTButton
                                                                    //             type={"list"}
                                                                    //             startIcon={<MyLocationIcon />}
                                                                    //             onClick={() => {
                                                                    //                 setCurrentImage(image360);
                                                                    //                 setInitialViewDialog(true);
                                                                    //             }}
                                                                    //         >
                                                                    //             Set start location
                                                                    //         </VTButton>
                                                                    //     ),
                                                                    // },
                                                                    {
                                                                        component: (
                                                                            <>
                                                                                <VTButton
                                                                                    type={"list"}
                                                                                    startIcon={<DownloadIcon />}
                                                                                    onClick={() => {
                                                                                        const imageUrl = `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${image360.id}/raw.jpg`;

                                                                                        const link =
                                                                                            document.createElement("a");
                                                                                        link.href = imageUrl;

                                                                                        document.body.appendChild(link);
                                                                                        link.click();

                                                                                        document.body.removeChild(link);
                                                                                    }}
                                                                                >
                                                                                    Download
                                                                                </VTButton>
                                                                            </>
                                                                        ),
                                                                    },
                                                                    {
                                                                        component: (
                                                                            <VTButton
                                                                                type={"list"}
                                                                                startIcon={<DeleteIcon />}
                                                                                onClick={() => {
                                                                                    setChangedItem(image360.id);
                                                                                    deleteMediaItem(
                                                                                        EDraggableOptions.IMMERSIVE_IMAGE,
                                                                                        userId,
                                                                                        image360.id,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </VTButton>
                                                                        ),
                                                                    },
                                                                ],
                                                                menuPopOutZIndex: 100,
                                                            }}
                                                        />
                                                    )}
                                                </Grid>
                                            ))}
                                </Grid>
                            )}
                        </>
                    )}
                    {activeButton === 1 && (
                        <VTMediaUploader
                            context="Immersive Images"
                            onComplete={() => setActiveButton(0)}
                            sx={{ marginTop: "-70px" }}
                        />
                    )}
                </Grid>
            </Grid>

            {/* {currentImage && (
                <VTLocation
                    currentScene={{
                        id: currentImage.id,
                        name: currentImage.name!,
                        src: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_image/${currentImage.id}`,
                        mediaType: EDraggableOptions.IMMERSIVE_IMAGE,
                        hotspots: [],
                        context: {},
                        startLocation: currentImage.startLocation ? currentImage.startLocation : [0, 0, 0],
                    }}
                    open={initialViewDialog}
                    setOpen={() => {
                        setInitialViewDialog(!initialViewDialog);
                    }}
                    onSave={(id, newPosition) => {
                        console.log("setting: ", id, newPosition);
                        // setSceneInitialView(id, newPosition);
                    }}
                />
            )} */}
        </>
    );
}
