import { SvgIcon, SxProps } from "@mui/material";

interface IIconProps{
    sx?: SxProps
}


export default function IconReport({sx}: IIconProps) {
    return (
        <SvgIcon viewBox="0 -960 960 960" sx={{ ...sx }}>
            <path d="M480-291.54q13.73 0 23.02-9.29t9.29-23.02q0-13.73-9.29-23.02-9.29-9.28-23.02-9.28t-23.02 9.28q-9.29 9.29-9.29 23.02t9.29 23.02q9.29 9.29 23.02 9.29Zm.01-139.23q12.76 0 21.37-8.62 8.62-8.63 8.62-21.38v-181.54q0-12.75-8.63-21.37-8.63-8.63-21.38-8.63-12.76 0-21.37 8.63-8.62 8.62-8.62 21.37v181.54q0 12.75 8.63 21.38 8.63 8.62 21.38 8.62ZM368.39-140q-14.64 0-27.9-5.62-13.26-5.61-23.1-15.46L161.08-317.39q-9.85-9.84-15.46-23.1-5.62-13.26-5.62-27.9v-223.22q0-14.64 5.62-27.9 5.61-13.26 15.46-23.1l156.31-156.31q9.84-9.85 23.1-15.46 13.26-5.62 27.9-5.62h223.22q14.64 0 27.9 5.62 13.26 5.61 23.1 15.46l156.31 156.31q9.85 9.84 15.46 23.1 5.62 13.26 5.62 27.9v223.22q0 14.64-5.62 27.9-5.61 13.26-15.46 23.1L642.61-161.08q-9.84 9.85-23.1 15.46-13.26 5.62-27.9 5.62H368.39Z" />{" "}
        </SvgIcon>
    );
}
