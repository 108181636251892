//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Grid, SxProps, TextField, useTheme } from "@mui/material";
// Custom components imports (and hooks and helpers)
import { useEffect, useRef, useState } from "react";

import DropImage from "../../assets/images/image_drop.svg";
import DropVideo from "../../assets/images/video_drop.svg";
import DropImmersiveImage from "../../assets/images/360_image_drop.svg";
import DropImmersiveVideo from "../../assets/images/360_video_drop.svg";
import DropHotspot from "../../assets/images/hotspot_drop.svg";
import DropAudio from "../../assets/images/audio_drop.svg";
import DropAnswer from "../../assets/images/answer_drop.svg";
import DropInfo from "../../assets/images/info_drop.svg";
import DropProceed from "../../assets/images/arrow_right.svg";
import TextHotspotDrop from "../../assets/images/text_hotspot_drop.svg";
import DropComplete from "../../assets/images/check_circle.svg";
import { VTTypography, useOnWindowResize } from "@virtus-tech-repository/virtus-tech-repository";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { useAppSelector } from "../../store/hooks";
import { Droppable } from "react-beautiful-dnd";
import { Provider } from "react-redux";
import { calculateProvidedBy } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import "../../App.css";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { DropPoint } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCreatorDropContainer";

interface IProps {
    mediaTypeBeingDragged?: EDraggableOptions;
    location: DropPoint;
    bottomDrawWidth: number;
    currentInterface?: IHotspot | undefined;
    droppableId: string;
    sx?: any;
}

export default function VTSidePanelMediaDropContainer({
    mediaTypeBeingDragged,
    location,
    droppableId,
    bottomDrawWidth,
    currentInterface,
    sx,
}: IProps) {
    // const { currentHotspot } = useAppSelector((state) => state.currentReducer);q
    const theme = useTheme();

    const [windowWidth, windowHeight] = useOnWindowResize();

    const [divContainerHeight, setDivContainerHeight] = useState<number | undefined>(undefined);

    const { currentHotspot } = useAppSelector((state) => state.currentReducer);

    useEffect(() => {
        console.log("resetting container height");
        window.setTimeout(() => {
            setDivContainerHeight(document.getElementById("canvas-drop-container")?.clientHeight);
            console.log(document.getElementById("canvas-drop-container")?.clientHeight);
        }, 500);
    }, [windowWidth, windowHeight]);

    return (
        <Droppable droppableId={droppableId} type="DROPPABLE_MEDIA" isDropDisabled={false}>
            {(provided, snapshot) => (
                <div
                    id="canvas-drop-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                        // transform: "none !important",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex:
                            mediaTypeBeingDragged === EDraggableOptions.IMMERSIVE_IMAGE ||
                            mediaTypeBeingDragged === EDraggableOptions.IMMERSIVE_VIDEO ||
                            mediaTypeBeingDragged === EDraggableOptions.ANSWER ||
                            mediaTypeBeingDragged === EDraggableOptions.INFORMATION ||
                            mediaTypeBeingDragged === EDraggableOptions.COMPLETE ||
                            mediaTypeBeingDragged === EDraggableOptions.MOVEMENT ||
                            mediaTypeBeingDragged === EDraggableOptions.VIDEO ||
                            mediaTypeBeingDragged === EDraggableOptions.IMAGE ||
                            mediaTypeBeingDragged === EDraggableOptions.AUDIO ||
                            mediaTypeBeingDragged === EDraggableOptions.TEMPLATE ||
                            mediaTypeBeingDragged === EDraggableOptions.TEXT_HOTSPOT ||
                            mediaTypeBeingDragged === EDraggableOptions.BULLET_POINTS_HOTSPOT
                                ? 1000
                                : -100,

                        // margin: location === DropPoint.DROP_CANVAS ? "25px" : "0px",
                        // backgroundColor: snapshot.isDraggingOver ? "lime" : "blue",

                        // Styling based on location; dynamically calc width for the canvas and timeline panel
                        height: currentHotspot.currentHotspot && currentHotspot.open ? "0" : "100%",
                        width: "100%",
                        ...sx,
                        // background: "green"
                    }}
                >
                    <div
                        style={{
                            ...(location === DropPoint.DROP_TIMELINE && {
                                height: "100%",
                            }),
                        }}
                    >
                        {mediaTypeBeingDragged && (
                            <div
                                style={{
                                    borderRadius: "15px",
                                    border: snapshot.isDraggingOver
                                        ? `4px dashed ${theme.palette.primary.light}`
                                        : `4px dashed ${theme.palette.primary.dark}`,
                                    backgroundColor: "rgba(0,0,0,0.8)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    flexDirection: "column",
                                    height: "100%",
                                    ...(location === DropPoint.DROP_CANVAS && {
                                        margin: "35px",
                                        height: `calc(100vh - ${bottomDrawWidth}px - 140px )`,
                                    }),
                                }}
                            >
                                {location === DropPoint.DROP_CANVAS && (
                                    <img
                                        src={
                                            mediaTypeBeingDragged === EDraggableOptions.IMMERSIVE_IMAGE
                                                ? DropImmersiveImage
                                                : mediaTypeBeingDragged === EDraggableOptions.IMAGE
                                                ? DropImage
                                                : mediaTypeBeingDragged === EDraggableOptions.IMMERSIVE_VIDEO
                                                ? DropImmersiveVideo
                                                : mediaTypeBeingDragged === EDraggableOptions.VIDEO
                                                ? DropVideo
                                                : mediaTypeBeingDragged === EDraggableOptions.AUDIO
                                                ? DropAudio
                                                : mediaTypeBeingDragged === EDraggableOptions.INFORMATION
                                                ? DropInfo
                                                : mediaTypeBeingDragged === EDraggableOptions.ANSWER
                                                ? DropAnswer
                                                : mediaTypeBeingDragged === EDraggableOptions.COMPLETE
                                                ? DropComplete
                                                : mediaTypeBeingDragged === EDraggableOptions.PROCEED
                                                ? DropProceed
                                                : mediaTypeBeingDragged === EDraggableOptions.TEXT_HOTSPOT
                                                ? TextHotspotDrop
                                                : mediaTypeBeingDragged === EDraggableOptions.BULLET_POINTS_HOTSPOT
                                                ? TextHotspotDrop
                                                : DropHotspot
                                        }
                                        height={"35%"}
                                    />
                                )}
                                {mediaTypeBeingDragged && (
                                    <h3
                                        className={snapshot.isDraggingOver ? "flash-font-colour" : undefined}
                                        style={{
                                            marginTop: "10px",
                                            color: "#bbb",
                                            textAlign: "center",
                                            marginLeft: "20px",
                                            marginRight: "20px",
                                        }}
                                    >
                                        {location === DropPoint.DROP_CANVAS
                                            ? `Please drop a ${mediaTypeBeingDragged.replaceAll(
                                                  "_",
                                                  " ",
                                              )} to add to the scene`
                                            : `Please drop a ${mediaTypeBeingDragged.replaceAll(
                                                  "_",
                                                  " ",
                                              )} to add new scene`}
                                    </h3>
                                )}
                            </div>
                        )}
                    </div>
                    {/* <Grid
                        sx={{
                            position: "absolute",
                            top: currentHotspot.currentHotspot ? "41px" : 0.5,
                            right:
                                location === DropPoint.DROP_CANVAS
                                    ? "calc(5% + " + (currentInterface ? "400px" : "0px") + ")"
                                    : 0,
                            // zIndex: 4,
                            // border: snapshot.isDraggingOver ? "4px dashed lime" : "4px dashed blue",
                            marginTop: location === DropPoint.DROP_CANVAS ? "50px" : 0,
                            // borderRadius: "15px",
                            backgroundColor: "rgba(0,0,0,0.6)",
                            // Styling based on location; dynamically calc height for the canvas and timeline panel
                            width:
                                location === DropPoint.DROP_CANVAS
                                    ? "calc(90% - " + (currentInterface ? 400 : 0) + "px)"
                                    : "100%",
                            // Styling based on location; dynamically calc width for the canvas and timeline panel
                            height:
                                location === DropPoint.DROP_CANVAS
                                    ? "calc(90vh - " + 85.5 + "px - " + bottomDrawWidth + "px)"
                                    : "calc(100% - " + 4 + "px)",
                        }}
                        container
                        justifyContent={"center"}
                        alignContent={"center"}
                        direction={"column"}
                    > */}

                    {/* </Grid>{" "} */}
                    {/* {provided.placeholder} */}
                </div>
            )}
        </Droppable>
    );
}
