import { SvgIcon, SxProps } from "@mui/material";
import { Background } from "@xyflow/react";

interface IIconProps{
    sx?: SxProps
}


export default function IconArchitecture({sx}: IIconProps) {
    return (
        <SvgIcon viewBox="0 -960 960 960" sx={{ ...sx }}>
            <path d="M430.92-477.85 327.15-191.39q-1 2.62-5 6.62l-22.38 21.38q-7.08 6.7-15.5 3.35t-10.04-11.88l-3.61-29.93q-.39-.77 1-8.61l107-295.38q11.53 10.53 24.61 17.34 13.08 6.81 27.69 10.65ZM480-530q-45.77 0-77.88-32.12Q370-594.23 370-640q0-39 23.46-67.38 23.46-28.39 56.54-36.93V-810q0-12.77 8.62-21.38Q467.23-840 480-840t21.38 8.62Q510-822.77 510-810v65.69q33.08 8.54 56.54 36.93Q590-679 590-640q0 45.77-32.12 77.88Q525.77-530 480-530Zm0-60q20.85 0 35.42-14.58Q530-619.15 530-640t-14.58-35.42Q500.85-690 480-690t-35.42 14.58Q430-660.85 430-640t14.58 35.42Q459.15-590 480-590Zm49.08 112.15q14.61-3.84 27.69-10.65 13.08-6.81 24.23-17.34l107 295.38q.61 1.84 1 8.61l-3.62 29.93q-1.61 8.53-10.03 11.88-8.43 3.35-15.5-3.35l-22.39-21.38-5-6.62-103.38-286.46Z" />{" "}
        </SvgIcon>
    );
}
