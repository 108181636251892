import { Grid } from "@mui/material";
import { VTButton, VTDialog } from "@virtus-tech-repository/virtus-tech-repository";
import HomeSettingsItem from "./HomeSettingsItem";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import {
    HOME_PAGE_OVERVIEW_TUTORIAL_DONE,
    MEDIA_PAGE_TUTORIAL_DONE,
    MEDIA_UPLOAD_TUTORIAL_DONE,
    SCENARIO_CREATION_TUTORIAL_DONE,
    SCENARIO_PUBLISHING_TUTORIAL_DONE,
} from "../../utils/joyRideTutorialsLocalStorageStrings";

interface IProps {
    open: boolean;
    handleClose: () => void;
}

export default function TutorialManagerDialog({ open, handleClose }: IProps) {
    const { currentSidePanel } = useAppSelector((state) => state.currentReducer);

    const [homeScenarios, setHomeScenarios] = useState<boolean>(false);
    const [homeOverview, setHomeOverview] = useState<boolean>(false);
    const [homePublishing, setHomePublishing] = useState<boolean>(false);

    const [mediaPage, setMediaPage] = useState<boolean>(false);
    const [mediaUpload, setMediaUpload] = useState<boolean>(false);

    useEffect(() => {
        setHomeScenarios(
            localStorage.getItem(SCENARIO_CREATION_TUTORIAL_DONE) &&
                localStorage.getItem(SCENARIO_CREATION_TUTORIAL_DONE) === "true"
                ? false
                : true,
        );

        setHomeOverview(
            localStorage.getItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE) &&
                localStorage.getItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE) === "true"
                ? false
                : true,
        );

        setHomePublishing(
            localStorage.getItem(SCENARIO_PUBLISHING_TUTORIAL_DONE) &&
                localStorage.getItem(SCENARIO_PUBLISHING_TUTORIAL_DONE) === "true"
                ? false
                : true,
        );

        setMediaPage(
            localStorage.getItem(MEDIA_PAGE_TUTORIAL_DONE) && localStorage.getItem(MEDIA_PAGE_TUTORIAL_DONE) === "true"
                ? false
                : true,
        );

        setMediaUpload(
            localStorage.getItem(MEDIA_UPLOAD_TUTORIAL_DONE) &&
                localStorage.getItem(MEDIA_UPLOAD_TUTORIAL_DONE) === "true"
                ? false
                : true,
        );
    }, []);

    function handleSave() {
        localStorage.setItem(SCENARIO_CREATION_TUTORIAL_DONE, homeScenarios ? "false" : "true");

        localStorage.setItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE, homeOverview ? "false" : "true");

        localStorage.setItem(SCENARIO_PUBLISHING_TUTORIAL_DONE, homePublishing ? "false" : "true");

        localStorage.setItem(MEDIA_PAGE_TUTORIAL_DONE, mediaPage ? "false" : "true");

        localStorage.setItem(MEDIA_UPLOAD_TUTORIAL_DONE, mediaUpload ? "false" : "true");

        handleClose();
    }

    return (
        <VTDialog
            disableEnforceFocus={false}
            title={"Tutorials"}
            open={open}
            handleClose={() => {
                handleClose();
            }}
            sx={{ marginLeft: currentSidePanel.currentWidth / 8 + 5, zIndex: 5, marginTop: "70px" }}
            primaryArea={
                <>
                    {/*---------------------------------------------------------------------------------*/}
                    {/* Dialog cancel button */}
                    <VTButton
                        color={"secondary"}
                        sx={
                            {
                                // width: "200px",
                                // height: "50px",
                                // marginRight: "30px",
                            }
                        }
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </VTButton>

                    <VTButton
                        sx={{
                            marginLeft: "5px",
                        }}
                        onClick={() => {
                            handleSave();
                        }}
                    >
                        Save
                    </VTButton>
                </>
            }
        >
            {/*------------------------------------------------------------------------------------*/}
            {/* Content Wrapper */}

            <Grid container sx={{ height: "100%", width: "100%", paddingTop: "20px" }}>
                <HomeSettingsItem
                    title={"Home Introduction"}
                    value={homeScenarios}
                    setValue={setHomeScenarios}
                    info={"Tutorial introducing the platform and the navigation"}
                    heading1="Disabled"
                    heading2="Enabled"
                    sx={{ marginBottom: "20px" }}
                />

                <HomeSettingsItem
                    title={"Scenario Creation"}
                    value={homeOverview}
                    setValue={setHomeOverview}
                    info={"Tutorial covering covering scenario creation and editing"}
                    heading1="Disabled"
                    heading2="Enabled"
                    sx={{ marginBottom: "20px" }}
                />

                <HomeSettingsItem
                    title={"Scenario Publishing"}
                    value={homePublishing}
                    setValue={setHomePublishing}
                    info={"Tutorial covering publishing existing scenarios from the overview page"}
                    heading1="Disabled"
                    heading2="Enabled"
                    sx={{ marginBottom: "20px" }}
                />

                <HomeSettingsItem
                    title={"Media Overview"}
                    value={mediaPage}
                    setValue={setMediaPage}
                    info={"Tutorial covering an overview of the media pages"}
                    heading1="Disabled"
                    heading2="Enabled"
                    sx={{ marginBottom: "20px" }}
                />

                <HomeSettingsItem
                    title={"Media Upload"}
                    value={mediaUpload}
                    setValue={setMediaUpload}
                    info={"Tutorial covering uploading of new media files"}
                    heading1="Disabled"
                    heading2="Enabled"
                    sx={{ marginBottom: "20px" }}
                />
            </Grid>
        </VTDialog>
    );
}
