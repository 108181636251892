import { SvgIcon, SxProps } from "@mui/material";
import { Background } from "@xyflow/react";


interface IIconProps{
    sx?: SxProps
}

export default function IconTask({sx}: IIconProps) {
    return (
        <SvgIcon viewBox="0 -960 960 960" sx={{ ...sx }}>
            <path d="M252.31-100Q222-100 201-121q-21-21-21-51.31v-615.38Q180-818 201-839q21-21 51.31-21h287.77q14.46 0 27.81 5.62 13.34 5.61 23.19 15.46l167.84 167.84q9.85 9.85 15.46 23.19 5.62 13.35 5.62 27.81v447.77Q780-142 759-121q-21 21-51.31 21H252.31ZM540-656.16q0 15.47 10.35 25.81Q560.69-620 576.16-620H720L540-800v143.84ZM437.38-348.85l-63.69-63.69q-4.46-4.46-9.73-6.69-5.27-2.23-10.84-2.23-5.58 0-11.35 2.23t-10.23 6.69q-9.31 9.31-9.31 21.39 0 12.07 9.31 21.38L412.69-288q5.62 5.61 11.85 7.92 6.23 2.31 13.46 2.31t13.46-2.31q6.23-2.31 11.85-7.92l165.15-165.16q8.92-8.92 9.12-21.49.19-12.58-9.12-21.89-9.31-9.31-21.69-9.31t-21.69 9.31l-147.7 147.69Z" />{" "}
        </SvgIcon>
    );
}
