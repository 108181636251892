import { SvgIcon, SxProps } from "@mui/material";
import { Background } from "@xyflow/react";

interface IIconProps{
    sx?: SxProps
}

export default function IconStairs({sx}: IIconProps) {
    return (
        <SvgIcon viewBox="0 -960 960 960" sx={{ ...sx }}>
            <path d="M407-383h73q12.75 0 21.37-8.63Q510-400.25 510-413v-103h73q12.75 0 21.37-8.63Q613-533.25 613-546v-104h67q12.75 0 21.37-8.63 8.63-8.63 8.63-21.38 0-12.76-8.63-21.37Q692.75-710 680-710h-97q-12.75 0-21.37 8.63Q553-692.75 553-680v103h-73q-12.75 0-21.37 8.63Q450-559.75 450-547v103h-73q-12.75 0-21.37 8.63Q347-426.75 347-414v104h-67q-12.75 0-21.37 8.63-8.63 8.63-8.63 21.38 0 12.76 8.63 21.37Q267.25-250 280-250h97q12.75 0 21.37-8.63Q407-267.25 407-280v-103ZM212.31-140Q182-140 161-161q-21-21-21-51.31v-535.38Q140-778 161-799q21-21 51.31-21h535.38Q778-820 799-799q21 21 21 51.31v535.38Q820-182 799-161q-21 21-51.31 21H212.31Z" />
        </SvgIcon>
    );
}
