import { useState } from "react";
import { VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { useTheme } from "@mui/material";
import getReactJoyrideStyleOverridesObject from "../../styles/getReactJoyrideStyleOverridesObject";
import { HOME_PAGE_OVERVIEW_TUTORIAL_DONE, SCENARIO_PUBLISHING_TUTORIAL_DONE } from "../../utils/joyRideTutorialsLocalStorageStrings";

interface IProps {
    runJoyrideOverview: boolean;
    setRunJoyrideOverview: (runJoyrideOverview: boolean) => void;
    runJoyrideCreators: boolean;
    setRunJoyrideCreators: (runJoyrideCreators: boolean) => void;
    runJoyrideSettings: boolean;
    setRunJoyrideSettings: (runJoyrideSettings: boolean) => void;
    runJoyridePublish: boolean;
    setRunJoyridePublish: (runJoyridePublish: boolean) => void;
}

export default function HomeScenarioDialogJoyride({
    runJoyrideOverview,
    setRunJoyrideOverview,
    runJoyrideCreators,
    setRunJoyrideCreators,
    runJoyrideSettings,
    setRunJoyrideSettings,
    runJoyridePublish,
    setRunJoyridePublish,
}: IProps) {
    const [stepsOverview] = useState<any>([
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Edit Overview</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        This model allows you to edit the meta data for a scenario
                    </VTTypography>
                </>
            ),
            placement: "center",
            target: "body",
            disableBeacon: true,
        },
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Title</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        Please write a tile and description that will be visible to the end user{" "}
                    </VTTypography>
                </>
            ),

            spotlightPadding: 0,
            target: ".overview",
            disableBeacon: true,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Preview</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        Optionally you can add a preview image to the scenario, we will need to upload some media first
                    </VTTypography>
                </>
            ),

            spotlightPadding: 0,
            target: ".overview2",
            disableBeacon: true,
        },
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Creators</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        Please navigate to the creators page to manage access to the scenario
                    </VTTypography>
                </>
            ),

            spotlightPadding: 0,
            target: ".creatorButton",
            disableBeacon: true,
            spotlightClicks: true,
        },
    ]);

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setRunJoyrideOverview(false);
        }
    };

    const [stepsCreators] = useState<any>([
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Creators</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        This page allows you to manage who has access to edit the scenario
                    </VTTypography>
                </>
            ),
            placement: "center",
            target: "body",
            disableBeacon: true,
        },
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Transfer</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        To allow for external access please select a group and use the arrows to move the group to
                        selected
                    </VTTypography>
                </>
            ),
            target: ".creators",
            disableBeacon: true,
            spotlightPadding: -10,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Settings</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        The settings page allow you to set scenario options such as allow experimental features.
                    </VTTypography>
                </>
            ),
            target: ".settingsButton",
            disableBeacon: true,
            spotlightPadding: 0,
            spotlightClicks: true,
        },
    ]);

    const handleJoyrideCreatorsCallback = (data: CallBackProps) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setRunJoyrideCreators(false);
        }
    };

    const [stepsSettings] = useState<any>([
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Settings</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        This page allows you to manage global settings for the scenario
                    </VTTypography>
                </>
            ),
            placement: "center",
            target: "body",
            disableBeacon: true,
        },
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Device</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        This setting enables features that are only available for desktop
                    </VTTypography>
                </>
            ),
            target: ".settingItem1",
            disableBeacon: true,
            spotlightPadding: 10,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Data</VTTypography>
                    <VTTypography type={"Paragraph"}>This setting enables user data to be recorded</VTTypography>
                </>
            ),
            target: ".settingItem2",
            disableBeacon: true,
            spotlightPadding: 10,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Score</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        If this is a marked scenario, this will show users a score at the end of a scenario
                    </VTTypography>
                </>
            ),
            target: ".settingItem3",
            disableBeacon: true,
            spotlightPadding: 10,
            spotlightClicks: true,
        },
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Save</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        When you're ready please save, if you cannot save please close this tooltip and go back to the
                        overview page to add a title
                    </VTTypography>
                </>
            ),
            target: ".saveButton",
            disableBeacon: true,
            spotlightPadding: 10,
            spotlightClicks: true,
        },
    ]);

    const handleJoyrideSettingsCallback = (data: CallBackProps) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setRunJoyrideSettings(false);
            localStorage.setItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE, "true");
        }
    };

    const [stepsPublish] = useState<any>([
        {
            content: (
                <>
                    <VTTypography type={"Heading2"}>Publish</VTTypography>
                    <VTTypography type={"Paragraph"}>
                        This page allows you to publish a scenario making it available to users, you will need to
                        re-publish after updating a scenario
                    </VTTypography>
                </>
            ),
            placement: "center",
            target: "body",
            disableBeacon: true,
        },
    ]);

    const handleJoyridePublishCallback = (data: CallBackProps) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            setRunJoyridePublish(false);

            localStorage.setItem(SCENARIO_PUBLISHING_TUTORIAL_DONE, "true");
        }
    };

    const theme = useTheme();
    const joyrideThemeOverridesObj = getReactJoyrideStyleOverridesObject(theme.palette.primary.main);

    return (
        <>
            <Joyride
                callback={handleJoyrideCallback}
                continuous
                run={runJoyrideOverview}
                scrollToFirstStep
                showProgress
                showSkipButton
                styles={joyrideThemeOverridesObj}
                steps={stepsOverview}
                disableScrolling
            />
            <Joyride
                callback={handleJoyrideCreatorsCallback}
                continuous
                run={runJoyrideCreators}
                scrollToFirstStep
                showProgress
                showSkipButton
                styles={joyrideThemeOverridesObj}
                steps={stepsCreators}
                disableScrolling
            />
            <Joyride
                callback={handleJoyrideSettingsCallback}
                continuous
                run={runJoyrideSettings}
                scrollToFirstStep
                showProgress
                showSkipButton
                styles={joyrideThemeOverridesObj}
                steps={stepsSettings}
                disableScrolling
            />
            <Joyride
                callback={handleJoyridePublishCallback}
                continuous
                run={runJoyridePublish}
                scrollToFirstStep
                showProgress
                showSkipButton
                styles={joyrideThemeOverridesObj}
                steps={stepsPublish}
                disableScrolling
            />
        </>
    );
}
