import IconInfo from "../../assets/icons/IconInfo";
import IconPrecision from "../../assets/icons/IconPrecision";
import { SxProps } from "@mui/material";
import IconArchitecture from "../../assets/icons/IconArchitecture";
import IconEngineering from "../../assets/icons/IconEngineering";
import IconHealthAndSafety from "../../assets/icons/IconHealthAndSaftey";
import IconHelp from "../../assets/icons/IconHelp";
import IconSupport from "../../assets/icons/IconSupport";
import IconMovement from "../../assets/icons/IconMovement";
import IconArrows from "../../assets/icons/IconArrows";
import IconFire from "../../assets/icons/IconFire";
import IconDual from "../../assets/icons/IconDual";
import IconReport from "../../assets/icons/IconReport";
import IconStairs from "../../assets/icons/IconStairs";
import IconTablet from "../../assets/icons/IconTablet";
import IconTask from "../../assets/icons/IconTask";
import IconWarning from "../../assets/icons/IconWarning";
import IconArrowHead from "../../assets/icons/IconArrowHead";
import { BlockRounded } from "@mui/icons-material";

export type IconName =
    | "Info"
    | "Movement"
    | "Arrows"
    | "Precision"
    | "Support"
    | "Architecture"
    | "Engineering"
    | "HealthAndSafety"
    | "Help"
    | "Fire"
    | "Dual"
    | "Report"
    | "Stairs"
    | "Tablet"
    | "Task"
    | "Warning"
    | "ArrowHead";

export const IconWrapperNames: IconName[] = [
    "Info",
    "Movement",
    "Arrows",
    "Precision",
    "Support",
    "Architecture",
    "Engineering",
    "HealthAndSafety",
    "Help",
    "Fire",
    "Dual",
    "Report",
    "Stairs",
    "Tablet",
    "Task",
    "Warning",
    "ArrowHead",
];

interface IconWrapperProps {
    iconName: IconName | undefined;
    sx?: SxProps;
}

export function IconWrapper({ iconName, sx }: IconWrapperProps) {
    switch (iconName) {
        case "Info":
            return <IconInfo sx={sx} />;
        case "Movement":
            return <IconMovement sx={sx} />;
        case "Arrows":
            return <IconArrows sx={sx} />;
        case "Precision":
            return <IconPrecision sx={sx} />;
        case "Support":
            return <IconSupport sx={sx} />;
        case "Architecture":
            return <IconArchitecture sx={sx} />;
        case "Engineering":
            return <IconEngineering sx={sx} />;
        case "HealthAndSafety":
            return <IconHealthAndSafety sx={sx} />;
        case "Help":
            return <IconHelp sx={sx} />;
        case "Fire":
            return <IconFire sx={sx} />;
        case "Dual":
            return <IconDual sx={sx} />;
        case "Report":
            return <IconReport sx={sx} />;
        case "Stairs":
            return <IconStairs sx={sx} />;
        case "Tablet":
            return <IconTablet sx={sx} />;
        case "Task":
            return <IconTask sx={sx} />;
        case "Warning":
            return <IconWarning sx={sx} />;
        case "ArrowHead":
            return <IconArrowHead sx={sx} />;
        default:
            return <BlockRounded sx={{ color: "red" }} />;
    }
}
