export const video2dAcceptedFileExtensions = [".mp4"];
export const image2dAcceptedFileExtensions = [".jpg", ".jpeg", ".png"];
export const audioAcceptedFileExtensions = [".mp3", ".wav"];

export enum EMediaFileType {
    VIDEO_2D,
    IMAGE_2D,
    AUDIO,
}

export const getMediaTypeFromFilePath = (filePath: string): EMediaFileType | undefined => {
    if (checkIfFilePathContainsFileExtention(filePath, image2dAcceptedFileExtensions)) {
        return EMediaFileType.IMAGE_2D;
    }

    if (checkIfFilePathContainsFileExtention(filePath, video2dAcceptedFileExtensions)) {
        // console.log("mp4");
        return EMediaFileType.VIDEO_2D;
    }

    if (checkIfFilePathContainsFileExtention(filePath, audioAcceptedFileExtensions)) {
        return EMediaFileType.AUDIO;
    }
};

const checkIfFilePathContainsFileExtention = (filePath: string, fileExtensionsArr: string[]): boolean => {
    filePath = filePath.toLowerCase();
    for (let i = 0; i < fileExtensionsArr.length; i++) {
        const fileType = fileExtensionsArr[i];
        if (filePath.includes(fileType)) {
            return true;
        }
    }
    return false;
};
