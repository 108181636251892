import { SvgIcon, SxProps } from "@mui/material";
import { Background } from "@xyflow/react";


interface IIconProps{
    sx?: SxProps
}


export default function IconFire({sx}: IIconProps) {
    return (
        <SvgIcon viewBox="0 -960 960 960" sx={{ ...sx }}>
            <path d="M180-400q0-101.46 58.54-197.19t160.54-168.35q19.3-14.23 40.5-2.84 21.19 11.38 21.19 34.92v33.92q0 36.69 29.84 64.12Q520.46-608 561-608q17.77 0 34.42-6.92 16.66-6.92 30.58-19.77 8-8.08 18.96-9.42 10.96-1.35 20.42 5.88 53.77 42.31 84.2 105.19Q780-470.15 780-400q0 89.92-47.62 161.65Q684.77-166.62 609-131.54q19.31-21.31 30.15-48.46Q650-207.15 650-238q0-33.46-12.69-63.77-12.7-30.31-36.47-54.07L480-474.07 360.16-355.84q-24.39 24.38-37.27 54.19Q310-271.85 310-238q0 30.85 10.85 58 10.84 27.15 30.15 48.46-75.77-35.08-123.38-106.81Q180-310.08 180-400Zm300 9.85 78.08 76.46q15.46 15.46 23.69 34.54Q590-260.08 590-238q0 44.77-32.12 76.38Q525.77-130 480-130q-45.77 0-77.88-31.62Q370-193.23 370-238q0-21.08 8.04-40.46 8.04-19.39 23.88-35.23L480-390.15Z" />{" "}
        </SvgIcon>
    );
}
