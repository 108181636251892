import {
    ReactFlow,
    Controls,
    Background,
    NodeTypes,
    Node,
    FitViewOptions,
    DefaultEdgeOptions,
    Edge,
    addEdge,
    useNodesState,
    useEdgesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import VTNodeScene from "./VTNodes/VTNodeScene";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import VTNodeHotspot from "./VTNodes/VTNodeHotspot";
import { Params, useParams } from "react-router-dom";
import { useGetScenarioOverviewQuery } from "../../services/scenario.service";

import "@xyflow/react/dist/style.css";

const nodeTypes: NodeTypes = {
    scene: VTNodeScene,
    hotspot: VTNodeHotspot,
};
const fitViewOptions: FitViewOptions = {
    padding: 0.2,
};

const defaultEdgeOptions: DefaultEdgeOptions = {
    animated: true,
};

interface IVTNodeEditorProps {
    scenes: IScene[] | undefined;
    hotspots: IHotspot[] | undefined;
}

export default function VTNodeWrapper({ scenes, hotspots }: IVTNodeEditorProps) {
    const { scenarioId }: Readonly<Params<string>> = useParams();

    const { data, isLoading } = useGetScenarioOverviewQuery(scenarioId ? scenarioId : "");

    const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);

    useEffect(() => {
        console.log("edges: ", edges);
    }, [edges]);

    useEffect(() => {
        console.log("data: ", data);

        if (data && data.scenes) {
            data.scenes.forEach((scene: IScene, i: number) => {
                setNodes((nodes) => [...nodes, SceneToNode(scene, i)]);

                if (scene.hotspots.length) {
                    scene.hotspots.forEach((hotspot: IHotspot, k: number) => {
                        if (hotspot.type !== "question") {
                            setNodes((oldNodes) => [...oldNodes, HotspotToNode(hotspot, i, k)]);

                            setEdges((oldEdges) => [
                                ...oldEdges,
                                {
                                    type: "smoothstep",
                                    sourceHandle: `scene-${scene.id}`,
                                    targetHandle: `hotspot-${hotspot.id}`,
                                    source: scene.id,
                                    target: hotspot.id,
                                    id: `scene-hotspot-${hotspot.id}`,
                                    animated: false,
                                },
                            ]);
                        } else if (hotspot.type == "question") {
                            setNodes((oldNodes) => [
                                ...oldNodes,
                                HotspotToNode(hotspot, i, k, {
                                    backgroundColor: "rgba(255, 0, 255, 0.2)",
                                    height: 220,
                                    width: 220,
                                }),
                            ]);

                            if (hotspot.groupedHotspots) {
                                hotspot.groupedHotspots.forEach((groupHotspot: IHotspot, j: number) => {
                                    setNodes((oldNodes) => [
                                        ...oldNodes,
                                        HotspotToNode(groupHotspot, i, k + j, undefined, hotspot.id),
                                    ]);
                                });
                            }

                            setEdges((oldEdges) => [
                                ...oldEdges,
                                {
                                    type: "smoothstep",
                                    sourceHandle: `scene-${scene.id}`,
                                    targetHandle: `hotspot-${hotspot.id}`,
                                    source: scene.id,
                                    target: hotspot.id,
                                    id: `scene-hotspot-${hotspot.id}`,
                                    animated: false,
                                },
                            ]);
                        }

                        if (hotspot.actions && hotspot.actions?.movement && hotspot.actions.movement.sceneId) {
                            console.log("Moving to scene of: ", hotspot.id, hotspot.actions!.movement!.sceneId!);
                            setEdges((oldEdges) => [
                                ...oldEdges,
                                {
                                    type: "smoothstep",
                                    sourceHandle: `hotspot-movement-${hotspot.id}`,
                                    targetHandle: `scene-movement-${hotspot.actions!.movement!.sceneId!}`,
                                    source: hotspot.id,
                                    target: hotspot.actions!.movement!.sceneId!,
                                    id: `scene-hotspot-movment-${hotspot.id}`,
                                    animated: true,
                                },
                            ]);
                        }
                    });
                }
            });
        }
    }, [data]);

    const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), []);

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                fitView
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                fitViewOptions={fitViewOptions}
                defaultEdgeOptions={defaultEdgeOptions}
                nodeTypes={nodeTypes}
                onConnect={onConnect}
                colorMode={"dark"}
            >
                <Background />
                <Controls />
            </ReactFlow>
        </div>
    );
}

function SceneToNode(scene: IScene, index: number): Node {
    console.log("DBG scene to node: ", scene);
    return {
        id: scene.id,
        data: { scene: scene },
        position: { x: index * 200, y: 0 },
        type: "scene",
    };
}

function HotspotToNode(
    hotspot: IHotspot,
    sceneIndex: number,
    hotspotIndex: number,
    style?: React.CSSProperties,
    parentId?: string,
): Node {
    console.log("hotpsot index: ", hotspotIndex);
    return {
        id: hotspot.id,
        data: { hotspot },
        position: { x: sceneIndex * 200, y: hotspotIndex * 200 + 200 },
        type: "hotspot",
        style,
        parentId,
    };
}
