import VTTimelineItem from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTTimelineItem";

import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";

import NoPreview from "../../../assets/images/NoScenarioImage.svg";
import NoPreviewFile from "../../../assets/images/NoFilesImage.svg";
import { Handle, Node, NodeProps, Position } from "@xyflow/react";
import { getImageForSceneOrDefaultImageIfPreviewImageDoesntExist } from "../../../utils/getImageForSceneOrDefaultImageIfPreviewImageDoesntExist";

type NodeData = {
    scene: IScene;
};

type CustomNode = Node<NodeData>;

export default function VTNodeScene({ data }: NodeProps<CustomNode>) {
    console.log("scene: ", data.scene);
    return (
        <>
            <Handle
                type="target"
                position={Position.Left}
                id={`scene-movement-${data.scene.id}`}
                isConnectable={true}
            />
            <Handle type="source" position={Position.Bottom} id={`scene-${data.scene.id}`} isConnectable={true} />

            <VTTimelineItem
                onClicked={() => {}}
                mediaType={data.scene.mediaType}
                disableHover
                image={getImageForSceneOrDefaultImageIfPreviewImageDoesntExist(data.scene)}
                name={data.scene.name}
            />
        </>
    );
}
