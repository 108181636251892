//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useRef, useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import * as THREE from "three";

// Material ui Imports
import {
    Box,
    CircularProgress,
    Grid,
    ImageList,
    ImageListItem,
    LinearProgress,
    Skeleton,
    Switch,
    SxProps,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

// Custom components imports (and hooks and helpers)
import {
    useLazyLoad,
    VTButton,
    VTCanvas,
    VTSidePanel360MediaCard,
    VTTextField,
    VTTypography,
    VTVideoControls,
} from "@virtus-tech-repository/virtus-tech-repository";
import {
    EDraggableOptions,
    EMediaOptions,
    IMediaItem,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Preview } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateAllImagesMutation,
    useDeleteMediaMutation,
    useGetAll360ImagesQuery,
    useGetAll360VideosQuery,
    usePreSignedUrlMutation,
    useUpdateAllScenarioMediaMutation,
} from "../../services/media.service";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import VTImmersiveVideoDraggable from "./VTImmersiveVideoDraggable";
import VTCreatorVideoControls from "./VTCreatorVideoControls";
import { Canvas } from "@react-three/fiber";
// Media imports
import NoVideos from "../../assets/images/NoVideos.svg";
import { Params, useParams } from "react-router-dom";
import SearchNotFound from "../../assets/images/NoVideos.svg";
import VTSphere from "./VTSphere";
import { ExposedCameraControlFunctions } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCanvasComponents/VTCameraControls";
import { setCurrentDoubleClick, setCurrentToast } from "../../store/slices/current.slice";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import { EDroppableContainerId } from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import VTImmersiveVideoUpload from "./VTImmersiveVideoUpload";
import WordingHelper from "../../helpers/wordingHelper";
import VTMediaUploader from "./VTMediaUploader";
import { setVideos360PrevPagesLoaded } from "../../store/slices/previouslyLazyLoadedMedia.slice";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components
//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sx?: SxProps;
    handleDragStart: any;
    handleDragStop: () => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTImmersiveVideoSidePanel({ handleDragStart, handleDragStop, sx }: any) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const cameraControlsRef = useRef<ExposedCameraControlFunctions>(null);
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);

    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();

    const [createImmersiveVideos] = useCreateAllImagesMutation();

    const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
    const [selectedVideoError, setSelectedVideoError] = useState<boolean>(false);
    const [videoName, setVideoName] = useState<string>("");
    const [videoId, setVideoId] = useState<string>(uuid());
    const [videoPreview, setVideoPreview] = useState<string | null>(null);
    const [capturePreview, setCapturePreview] = useState<boolean>(false);

    const [highlight, setHighlight] = useState(false);

    const { data: videos, isLoading: videosLoading, refetch } = useGetAll360VideosQuery(userId);
    const { data: scenarioVideos, isLoading: scenarioVideosLoading } = useGetAll360VideosQuery(scenarioId!);

    const [updateAllScenarioMedia, { isLoading: updateLoading }] = useUpdateAllScenarioMediaMutation();
    const [deleteAllScenarioMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();

    const [currentItemUpdate, setCurrentItemUpdate] = useState<string | undefined>(undefined);

    const dispatch = useAppDispatch();

    const { currentScenarioIsUpdating, currentlyDragging, currentSidePanel } = useAppSelector((state) => state.currentReducer);

    const theme = useTheme();
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const [checked, setChecked] = useState(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedVideo) {
            if (checked) {
                createImmersiveVideos({
                    id: userId,
                    media_id: videoId,
                    media_type: "three_sixty_video",
                    filetype: selectedVideo.type.split("/")[1],
                    name: videoName,
                    description: "",
                    date: Date.now(),
                    tags: [],
                    preview: capturePreview,
                    organisation: organisation,
                    chopping: "processing",
                });
            } else {
                createImmersiveVideos({
                    id: userId,
                    media_id: videoId,
                    media_type: "three_sixty_video",
                    filetype: selectedVideo.type.split("/")[1],
                    name: videoName,
                    description: "",
                    date: Date.now(),
                    tags: [],
                    preview: capturePreview,
                    organisation: organisation,
                });
            }
        }
    }, [mediaUploadCompleted]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    const handleButtonClick = (buttonIndex: number) => {
        setActiveButton(buttonIndex);
    };

    const { sidePanelMedia, creatorComponentFirstMountedDate } = useAppSelector(
        (state) => state.previouslyLazyLoadedMediaReducer,
    );

    

    const triggerRef = useRef<HTMLDivElement>(null);

    const videosToLoadPerLazyLoad = 5;

    const lazyLoadTimeInMs = 1000;

    const initialMediaLoadedRef = useRef<boolean>();
    const initialPagesToConsiderRef = useRef<number>();

    const currentPageRef = useRef<number>(0);

    useEffect(() => {
        // only run this code on component mount
        initialMediaLoadedRef.current = sidePanelMedia.videos360.prevPagesLoaded === 0 ? true : false;
        initialPagesToConsiderRef.current =
            sidePanelMedia.videos360.prevPagesLoaded! > 0 ? sidePanelMedia.videos360.prevPagesLoaded! - 1 : 0;
    }, []);

    const onGrabData = (currentPage: number): Promise<IMediaItem[]> => {
        currentPageRef.current = currentPage;

        return new Promise((resolve) => {
            if (!videosLoading && creatorComponentFirstMountedDate !== null) {
                if (videos) {
                    if (initialMediaLoadedRef.current) {
                        setTimeout(() => {
                            const startingIndex =
                                (initialPagesToConsiderRef.current! + currentPage - 1) * videosToLoadPerLazyLoad;

                            const endIndex =
                                (initialPagesToConsiderRef.current! + currentPage) * videosToLoadPerLazyLoad;

                            let data = videos
                                .slice()
                                .filter((item: IMediaItem) => item.date < creatorComponentFirstMountedDate!)
                                .sort((a: IMediaItem, b: IMediaItem) => {
                                    return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                })
                                .slice(startingIndex, endIndex);
                            resolve(data);
                        }, lazyLoadTimeInMs);
                    } else {
                        let data = videos
                            .slice()
                            .filter((item: IMediaItem) => item.date < creatorComponentFirstMountedDate!)
                            .sort((a: IMediaItem, b: IMediaItem) => {
                                return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                            })
                            .slice(0, sidePanelMedia.videos360.prevPagesLoaded! * videosToLoadPerLazyLoad);
                        resolve(data);
                        initialMediaLoadedRef.current = true;
                    }
                }
            }
        });
    };

    const [dataLength, setDataLength] = useState<number>(
        videos!.filter((item: IMediaItem) => item.date < creatorComponentFirstMountedDate!).length,
    );

    const videos360scrollContainerRef = useRef<HTMLDivElement>(null);

    const { data: lazyLoadedVideos, loading: lazyLoadingInProgress } = useLazyLoad<IMediaItem>({
        triggerRef,
        onGrabData,
        dataLength,
        scrollContainerRef: videos360scrollContainerRef,
        activeButton: activeButton,
    });

    let recentlyUploadedVideos = videos?.slice().filter((mediaItem: IMediaItem) => {
        return mediaItem.date > creatorComponentFirstMountedDate!;
    });

    const loadedVideoDataAndRecentUploadedVideos = [
        recentlyUploadedVideos!.sort((a, b) => {
            return new Date(b.date).valueOf() - new Date(a.date).valueOf();
        }),
        lazyLoadedVideos!,
    ].flat();

    // console.log("loadedVideoDataAndRecentUploadedVideos", loadedVideoDataAndRecentUploadedVideos);
    // console.log("videos", videos);

    useEffect(() => {
        dispatch(setVideos360PrevPagesLoaded(Math.ceil(lazyLoadedVideos.length / videosToLoadPerLazyLoad)));
    }, [lazyLoadedVideos]);

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show available Videos
    return (
        <Grid container xs={12} justifyContent={"center"} sx={{ overflowY: "hidden", userSelect: "none", ...sx }}>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Video search */}
            <Grid item xs={11}>
                <VTTextField
                    placeholder={"Search 360 Videos"}
                    sx={{
                        marginTop: "20px",
                    }}
                    value={searchValue}
                    onChange={(textInput: string) => setSearchValue(textInput)}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Filters */}
            <Grid
                item
                container
                xs={11}
                sx={{
                    marginTop: "15px",
                    display: "flex",
                    overflowX: "auto",
                }}
            >
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(0)}
                        sx={{
                            borderBottom: activeButton === 0 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography fontSize={"1em"}>All Videos</Typography>
                    </VTButton>
                </Grid>

                <Grid item xs={4}>
                    {scenarioVideos && (
                        <VTButton
                            onClick={() => handleButtonClick(2)}
                            sx={{
                                borderBottom: activeButton === 2 ? `3px solid ${theme.palette.primary.main}` : "none",
                                maxWidth: "100%",
                            }}
                            type="underline"
                        >
                            <Typography fontSize={"1em"}>{WordingHelper(organisation)} Videos</Typography>
                        </VTButton>
                    )}
                </Grid>
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(1)}
                        sx={{
                            borderBottom: activeButton === 1 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography fontSize={"1em"}>Upload Videos</Typography>
                    </VTButton>
                </Grid>
            </Grid>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Video upload */}
            {activeButton === 1 && (
                <VTMediaUploader
                    onComplete={() => setActiveButton(0)}
                    sx={{ overflowX: "auto", height: "calc(100vh - 224px)" }}
                    context={"Immersive Videos"}
                />
            )}
            {activeButton === 0 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Video list */}
                    {videosLoading ? (
                        <Box
                            component="div"
                            sx={{
                                marginTop: "5px",
                                overflowY: "scroll",
                                width: "100%",
                                padding: "20px",
                                height: "calc(100vh - 230px)",
                            }}
                        >
                            <VTSidePanel360MediaCard sx={{ marginBottom: "8px" }} name={""} loading={true} />
                            <VTSidePanel360MediaCard sx={{ marginBottom: "8px" }} name={""} loading={true} />
                            <VTSidePanel360MediaCard sx={{}} name={""} loading={true} />
                        </Box>
                    ) : (
                        <>
                            {!videos || videos.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"} justifyContent={"center"}>
                                    <img src={NoVideos} style={{ maxWidth: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first video file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Box
                                    // ref={provided.innerRef}
                                    // {...provided.droppableProps}
                                    component="div"
                                    sx={{
                                        marginTop: "5px",
                                        overflowY: "scroll",
                                        width: "100%",
                                        padding: "20px",
                                        height: "calc(100vh - 230px)",
                                    }}
                                    ref={videos360scrollContainerRef}
                                >
                                    {videos?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Droppable
                                            droppableId={EDroppableContainerId.MEDIA_360_VIDEOS}
                                            type="DROPPABLE_MEDIA"
                                            // isDropDisabled={false}
                                            // renderClone={(provided, snapshot, rubric) => (
                                            //     <div
                                            //         {...provided.draggableProps}
                                            //         {...provided.dragHandleProps}
                                            //         ref={provided.innerRef}
                                            //     >
                                            //         Item id: {images[rubric.source.index].id}
                                            //     </div>
                                            // )}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    style={{ transform: "none !important" }}
                                                >
                                                    {loadedVideoDataAndRecentUploadedVideos
                                                        ?.filter(
                                                            (item: IMediaItem) =>
                                                                searchValue === "" ||
                                                                item
                                                                    .name!.toLowerCase()
                                                                    .includes(searchValue.toLocaleLowerCase()) ||
                                                                item
                                                                    .description!.toLowerCase()
                                                                    .includes(searchValue.toLocaleLowerCase()),
                                                        )
                                                        .sort((a: IMediaItem, b: IMediaItem) => {
                                                            return (
                                                                new Date(b.date).valueOf() - new Date(a.date).valueOf()
                                                            );
                                                        })
                                                        .map((item: IMediaItem, i) => {
                                                            // console.log("item preview", item.preview);
                                                            return (
                                                                <Draggable
                                                                    key={item.id}
                                                                    draggableId={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${item.id}/${item.id}.${item.filetype}`}
                                                                    index={i}
                                                                    isDragDisabled={
                                                                        item.chopping === "error" ||
                                                                        item.chopping === "processing"
                                                                    }
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <>
                                                                            <VTSidePanel360MediaCard
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                ref={provided.innerRef}
                                                                                nameCutOffPoint={currentSidePanel.currentWidth === 400 ? 32 : 20}
                                                                                sx={{
                                                                                    marginBottom: "8px",
                                                                                    // zIndex: 1,
                                                                                    transform:
                                                                                        !snapshot.isDragging ||
                                                                                        (currentlyDragging &&
                                                                                            !snapshot.isDragging)
                                                                                            ? "none !important"
                                                                                            : "auto",
                                                                                    ...(snapshot.isDragging && {
                                                                                        border: `4px dashed ${theme.palette.primary.light}`,
                                                                                        borderRadius: "16px",
                                                                                    }),
                                                                                }}
                                                                                cardType360="video"
                                                                                loading={currentScenarioIsUpdating}
                                                                                processingErrorMsg={
                                                                                    item.chopping === "error"
                                                                                        ? "There has been an error processing this image, please try again"
                                                                                        : undefined
                                                                                }
                                                                                processingMsg={
                                                                                    item.chopping === "processing"
                                                                                        ? "The image is being processed, please wait and refresh your page"
                                                                                        : undefined
                                                                                }
                                                                                pathTo360Images={undefined}
                                                                                previewImageSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${item.id}/${item.id}_preview.jpg`}
                                                                                name={item.name!}
                                                                                onHandleDoubleClick={() => {
                                                                                    if (
                                                                                        item.chopping ===
                                                                                            "processing" ||
                                                                                        item.chopping === "error"
                                                                                    ) {
                                                                                        return;
                                                                                    }

                                                                                    dispatch(
                                                                                        setCurrentDoubleClick({
                                                                                            src: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${item.id}/${item.id}.${item.filetype}`,
                                                                                            mediaType:
                                                                                                EDraggableOptions.IMMERSIVE_VIDEO,
                                                                                        } as IDragOptions),
                                                                                    );
                                                                                }}
                                                                                onHandleDragStart={() => {
                                                                                    // console.log("on handle drag start");
                                                                                    // localHandleDrag(
                                                                                    //     true,
                                                                                    //     EDragContentOptions.IMAGE_360,
                                                                                    //     `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${item.id}/${item.id}.${item.filetype}`,
                                                                                    // );
                                                                                }}
                                                                                onHandleDragEnd={() => {
                                                                                    // console.log("on handle drag end");
                                                                                    // localHandleDrag(false);
                                                                                }}
                                                                            />{" "}
                                                                            {snapshot.isDragging && (
                                                                                <div
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            theme.palette.background
                                                                                                .paper,
                                                                                        width: "100%",
                                                                                        height: "103px",
                                                                                        borderRadius: "15px",
                                                                                        transform: "none !important",
                                                                                        marginBottom: "10px",
                                                                                    }}
                                                                                ></div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </Draggable>
                                                                // <VTImmersiveVideoDraggable
                                                                //     key={item.id}
                                                                //     item={item}
                                                                //     organisation={organisation}
                                                                //     localHandleDrag={localHandleDrag}
                                                                //     handleDoubleClick={handleDoubleClick}
                                                                // />
                                                            );
                                                        })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    )}{" "}
                                    {/* {provided.placeholder} */}
                                    <div ref={triggerRef} style={{}}>
                                        {lazyLoadingInProgress && (
                                            <>
                                                {Array.from({ length: videosToLoadPerLazyLoad }).map(() => {
                                                    return (
                                                        <VTSidePanel360MediaCard
                                                            sx={{ marginBottom: "8px" }}
                                                            name={""}
                                                            loading={true}
                                                        />
                                                    );
                                                })}
                                                {/* <VTSidePanel360MediaCard sx={{}} name={""} loading={true} /> */}
                                            </>
                                        )}
                                    </div>
                                </Box>
                            )}
                        </>
                    )}
                </>
            )}

            {activeButton === 2 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Video list */}
                    {scenarioVideosLoading ? (
                        <Box
                            component="div"
                            sx={{
                                marginTop: "5px",
                                overflowY: "scroll",
                                width: "100%",
                                padding: "20px",
                                height: "calc(100vh - 230px)",
                            }}
                        >
                            <VTSidePanel360MediaCard sx={{ marginBottom: "8px" }} name={""} loading={true} />
                            <VTSidePanel360MediaCard sx={{ marginBottom: "8px" }} name={""} loading={true} />
                            <VTSidePanel360MediaCard sx={{}} name={""} loading={true} />
                        </Box>
                    ) : (
                        <>
                            {!scenarioVideos || scenarioVideos.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={NoVideos} style={{ maxWidth: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first video file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Box
                                    component="div"
                                    sx={{
                                        marginTop: "5px",
                                        overflowY: "scroll",
                                        width: "100%",
                                        padding: "20px",
                                        height: "calc(100vh - 230px)",
                                    }}
                                >
                                    {scenarioVideos?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        scenarioVideos
                                            ?.filter(
                                                (item: IMediaItem) =>
                                                    searchValue === "" ||
                                                    item
                                                        .name!.toLowerCase()
                                                        .includes(searchValue.toLocaleLowerCase()) ||
                                                    item
                                                        .description!.toLowerCase()
                                                        .includes(searchValue.toLocaleLowerCase()),
                                            )
                                            .sort((a: IMediaItem, b: IMediaItem) => {
                                                return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                            })
                                            .map((item: IMediaItem, index: number) => (
                                                // <>
                                                //     {(updateLoading || deleteLoading) &&
                                                //     currentItemUpdate == item.id ? (
                                                //         <Grid item xs={6}>
                                                //             <Skeleton
                                                //                 sx={{
                                                //                     height: "300px",
                                                //                     margin: "10px",
                                                //                     marginBlock: "-25px",
                                                //                     marginTop: "-55px",
                                                //                 }}
                                                //             />
                                                //         </Grid>
                                                //     ) : (
                                                <VTSidePanel360MediaCard
                                                nameCutOffPoint={currentSidePanel.currentWidth === 400 ? 32 : 16}
                                                    sx={{
                                                        marginBottom: "8px",
                                                        zIndex: 1,
                                                    }}
                                                    cardType360="video"
                                                    loading={
                                                        (updateLoading || deleteLoading) && currentItemUpdate == item.id
                                                    }
                                                    processingErrorMsg={
                                                        item.chopping === "error"
                                                            ? "There has been an error processing this image, please try again"
                                                            : undefined
                                                    }
                                                    processingMsg={
                                                        item.chopping === "processing"
                                                            ? "The image is being processed, please wait and refresh your page"
                                                            : undefined
                                                    }
                                                    pathTo360Images={
                                                        item.chopping !== "processing" && item.chopping !== "error"
                                                            ? undefined
                                                            : undefined
                                                    }
                                                    previewImageSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${item.id}/${item.id}_preview.jpg`}
                                                    name={item.name!}
                                                    onAddButtonClicked={() => {
                                                        setCurrentItemUpdate(item.id);
                                                        updateAllScenarioMedia({
                                                            id: userId,
                                                            media_type: EDraggableOptions.IMMERSIVE_VIDEO,
                                                            media_ids: [item.id],
                                                        });
                                                    }}
                                                    onHandleDoubleClick={() => {}}
                                                    onHandleDragStart={() => {
                                                        // console.log("on handle drag start");
                                                        // localHandleDrag(
                                                        //     true,
                                                        //     EDragContentOptions.IMAGE_360,
                                                        //     `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${item.id}/${item.id}.${item.filetype}`,
                                                        // );
                                                    }}
                                                    onHandleDragEnd={() => {
                                                        // console.log("on handle drag end");
                                                        // localHandleDrag(false);
                                                    }}
                                                />
                                                // <VTImmersiveVideoDraggable
                                                //     item={item}
                                                //     organisation={organisation}
                                                //     localHandleDrag={localHandleDrag}
                                                //     handleDoubleClick={handleDoubleClick}
                                                //     addFunction={() => {
                                                //         setCurrentItemUpdate(item.id);
                                                //         updateAllScenarioMedia({
                                                //             id: userId,
                                                //             media_type: EMedia.VIDEO_360,
                                                //             media_ids: [item.id],
                                                //         });
                                                //     }}
                                                //     deleteFunction={() => {
                                                //         setCurrentItemUpdate(item.id);
                                                //         deleteAllScenarioMedia({
                                                //             mediaType: EMedia.VIDEO_360,
                                                //             collectionId: scenarioId!,
                                                //             mediaId: item.id,
                                                //         });
                                                //     }}
                                                // />
                                                //     )}
                                                // </>
                                            ))
                                    )}
                                </Box>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
