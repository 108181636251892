import VTTimelineItem from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTTimelineItem";

import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";

import NoPreview from "../../../assets/images/NoScenarioImage.svg";
import NoPreviewFile from "../../../assets/images/NoFilesImage.svg";
import { Handle, Node, NodeProps, Position } from "@xyflow/react";
import { getImageForSceneOrDefaultImageIfPreviewImageDoesntExist } from "../../../utils/getImageForSceneOrDefaultImageIfPreviewImageDoesntExist";
import { IHotspot } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import { VTHTMLHotspot } from "@virtus-tech-repository/virtus-tech-repository";
import { useEffect } from "react";
import { IconName, IconWrapper } from "../IconWrapper";
import { checkIconExistsInIconWrapper } from "../../../utils/checkIconExists";
import { Box } from "@mui/material";

type NodeData = {
    hotspot: IHotspot;
};

type CustomNode = Node<NodeData>;

export default function VTNodeHotspot({ data }: NodeProps<CustomNode>) {
    useEffect(() => {
        console.log("hotspot data ***: ", data);
    }, [data]);
    return (
        <>
            <Handle
                type="source"
                position={Position.Right}
                id={`hotspot-movement-${data.hotspot.id}`}
                isConnectable={true}
            />
            <Handle type="target" position={Position.Top} id={`hotspot-${data.hotspot.id}`} isConnectable={true} />

            <Box
                component="div"
                // sx={{
                //     backgroundColor: "rgba(255, 0, 255, 0.2)",
                //     height: 320,
                //     width: 320,
                // }}
            >
                {data.hotspot && (
                    <VTHTMLHotspot
                        onClose={undefined}
                        sx={{
                            "> div:nth-child(1) > div": {
                                border: `3px solid ${
                                    data.hotspot.styles?.preClickStyles?.colours?.border &&
                                    `rgba(${data.hotspot.styles?.preClickStyles?.colours?.border.r}, ${data.hotspot.styles?.preClickStyles?.colours?.border.g}, ${data.hotspot.styles?.preClickStyles?.colours?.border.b}, ${data.hotspot.styles?.preClickStyles?.colours?.border.a})`
                                }`,
                                background: `${
                                    data.hotspot.styles?.preClickStyles?.colours?.background
                                        ? `rgba(${data.hotspot.styles?.preClickStyles?.colours?.background.r}, ${data.hotspot.styles?.preClickStyles?.colours?.background.g}, ${data.hotspot.styles?.preClickStyles?.colours?.background.b}, ${data.hotspot.styles?.preClickStyles?.colours?.background.a})`
                                        : "rgba(30,30,30,0.5)"
                                } `,
                                h3: {
                                    color: `${
                                        data.hotspot.styles?.preClickStyles?.colours?.text
                                            ? `rgba(${data.hotspot.styles?.preClickStyles?.colours?.text.r}, ${data.hotspot.styles?.preClickStyles?.colours?.text.g}, ${data.hotspot.styles?.preClickStyles?.colours?.text.b}, ${data.hotspot.styles?.preClickStyles?.colours?.text.a})`
                                            : "#fff"
                                    } !important`,
                                },
                            },
                            "> div:nth-child(2) > div": {
                                background: `${
                                    data.hotspot.styles?.preClickStyles?.colours?.background
                                        ? `rgba(${data.hotspot.styles?.preClickStyles?.colours?.background.r}, ${data.hotspot.styles?.preClickStyles?.colours?.background.g}, ${data.hotspot.styles?.preClickStyles?.colours?.background.b}, ${data.hotspot.styles?.preClickStyles?.colours?.background.a})`
                                        : "rgba(30,30,30,0.5)"
                                } `,
                                border: `3px solid ${
                                    data.hotspot.styles?.preClickStyles?.colours?.border &&
                                    `rgba(${data.hotspot.styles?.preClickStyles?.colours?.border.r}, ${data.hotspot.styles?.preClickStyles?.colours?.border.g}, ${data.hotspot.styles?.preClickStyles?.colours?.border.b}, ${data.hotspot.styles?.preClickStyles?.colours?.border.a})`
                                }`,
                                div: {
                                    color: `${
                                        data.hotspot.styles?.preClickStyles?.colours?.text
                                            ? `rgba(${data.hotspot.styles?.preClickStyles?.colours?.text.r}, ${data.hotspot.styles?.preClickStyles?.colours?.text.g}, ${data.hotspot.styles?.preClickStyles?.colours?.text.b}, ${data.hotspot.styles?.preClickStyles?.colours?.text.a})`
                                            : "#fff"
                                    }`,
                                },
                            },
                        }}
                        title={data.hotspot.contents.preClickContent.title}
                        icon={
                            checkIconExistsInIconWrapper(data.hotspot.contents.preClickContent.icon) ? (
                                <IconWrapper iconName={data.hotspot.contents.preClickContent.icon as IconName} />
                            ) : undefined
                        }
                        vtRichTextEditorContent={
                            data.hotspot.contents.preClickContent.contentData &&
                            typeof data.hotspot.contents.preClickContent.contentData === "string" &&
                            data.hotspot.contents.preClickContent.contentData.replace(/\s+/g, "") !==
                                `<divclass="rte-container"></div>`
                                ? {
                                      content: data.hotspot.contents.preClickContent.contentData,
                                  }
                                : undefined
                        }
                    />
                )}
            </Box>
        </>
    );
}
