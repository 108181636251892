import { Grid, SxProps, Tooltip } from "@mui/material";
import { VTMenu } from "@virtus-tech-repository/virtus-tech-repository";
import { ArrowDropDownRounded, BlockRounded } from "@mui/icons-material";
import { ReactElement } from "react";
import { IconName, IconWrapperNames, IconWrapper } from "./IconWrapper";

interface IIconContainerProps {
    title?: string;
    onClick: () => void;
    children: ReactElement;
}
function IconContainer({ title, onClick, children }: IIconContainerProps) {
    return (
        <Tooltip title={title} sx={{ zIndex: 1000000 }}>
            <Grid
                item
                xs={2}
                container
                justifyContent={"center"}
                alignContent={"center"}
                sx={{ cursor: "pointer" }}
                onClick={onClick}
            >
                {children}
            </Grid>
        </Tooltip>
    );
}

interface IVTIconDropDownProps {
    currentIcon: IconName | undefined;
    setCurrentIcon: (preClickIcon: string | undefined) => void;
    setUnsavedChanges?: (unsavedChanges: boolean) => void;
    sx?: SxProps;
}

export default function VTIconDropDown({
    currentIcon,
    setCurrentIcon,
    setUnsavedChanges = () => {},
    sx,
}: IVTIconDropDownProps) {
    return (
        <Grid item>
            <VTMenu
                sx={{
                    ".MuiIconButton-root": {
                        backgroundColor: "#444547",
                        border: "1px solid grey",
                        borderRadius: "15px",
                        paddingBlock: "18px",
                        ":hover": {
                            backgroundColor: "#444547",
                            border: "1px solid white",
                        },
                    },
                    ...sx,
                }}
                type="icon"
                menuOptions={[
                    {
                        component: (
                            <Grid container sx={{ width: "200px", marginBottom: "5px", paddingInline: "5px" }}>
                                {IconWrapperNames.map((iconName: IconName) => {
                                    return (
                                        <IconContainer
                                            onClick={() => {
                                                setCurrentIcon(iconName);
                                                setUnsavedChanges(true);
                                            }}
                                        >
                                            <IconWrapper
                                                iconName={iconName}
                                                sx={{
                                                    ...(currentIcon === iconName && {
                                                        backgroundColor: "rgba(132,38,223, 0.4)",
                                                        boxShadow: "0 0 15px #8426df",
                                                        borderRadius: "15px",
                                                        transition: "0.5s",
                                                    }),
                                                }}
                                            />
                                        </IconContainer>
                                    );
                                })}

                                <IconContainer
                                    title={"No Icon"}
                                    onClick={() => {
                                        setCurrentIcon(undefined);
                                        setUnsavedChanges(true);
                                    }}
                                >
                                    <BlockRounded sx={{ color: "red" }} />
                                </IconContainer>
                            </Grid>
                        ),
                    },
                ]}
                popOutMenuZIndex={10000}
            >
                <IconWrapper iconName={currentIcon} />
                <ArrowDropDownRounded />
            </VTMenu>
        </Grid>
    );
}
