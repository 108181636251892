import { SvgIcon, SxProps } from "@mui/material";
import { Background } from "@xyflow/react";

interface IIconProps{
    sx?: SxProps
}

export default function IconDual({sx}: IIconProps) {
    return (
        <SvgIcon viewBox="0 -960 960 960" sx={{ ...sx }}>
            <path d="M225.27-138.31q-9.5 8.31-22.08 8-12.57-.31-20.97-9.51-33.76-36.57-54.99-80.83Q106-264.92 106-315q0-48.91 13.62-94.92 13.61-46 33.23-90.23 15.77-36.64 28.96-74.17Q195-611.85 195-651.92q0-37.46-18.96-69.15-18.96-31.7-46.38-57.26-9.04-8.29-8.85-20.36.19-12.08 8.5-21.33 8.31-9.36 20.57-9.67 12.27-.31 21.58 8 36.85 33.46 60.19 76.73Q255-701.69 255-652q0 46.23-13.5 89.38-13.5 43.16-31.73 85.48-17 39.06-30.39 78.6Q166-358.99 166-316.03q0 38.95 16.96 73.38 16.96 34.42 44.12 62.57 8.3 8.93 7.8 21.2-.5 12.26-9.61 20.57Zm160 0q-9.5 8.31-22.08 8-12.57-.31-20.97-9.51-33.76-36.57-54.99-80.83Q266-264.92 266-315q0-48.91 13.62-94.92 13.61-46 33.23-90.23 15.77-36.64 28.96-74.17Q355-611.85 355-651.92q0-37.46-18.96-69.15-18.96-31.7-46.38-57.26-9.04-8.29-8.85-20.55.19-12.27 8.5-21.39 8.31-9.5 20.57-9.61 12.27-.12 21.58 8.19 36.85 33.46 60.19 76.54Q415-702.08 415-651.94q0 45.79-13.31 89.02t-31.54 85.07q-17 39.16-30.57 79.01Q326-358.98 326-316.02q0 38.94 16.96 73.37 16.96 34.42 44.12 62.57 8.3 8.93 7.8 21.2-.5 12.26-9.61 20.57ZM487.69-120v-720q0-12.77 8.62-21.38 8.61-8.62 21.38-8.62t21.39 8.62q8.61 8.61 8.61 21.38v149.85l118.93-117.54q8.69-8.69 20.76-8.69 12.08 0 21.39 9.3 8.69 8.7 8.69 21.08 0 12.38-8.69 21.08L547.69-603.85V-510h93.85l161.08-161.08q8.69-8.69 21.07-8.69 12.39 0 21.08 8.69 8.31 8.31 8.31 20.89 0 12.57-8.31 21.27L727.84-510h149.85q12.77 0 21.39 8.62 8.61 8.61 8.61 21.38t-8.61 21.38q-8.62 8.62-21.39 8.62H727.84l117.54 118.92q8.7 8.7 8.7 20.77 0 12.08-9.31 21.39-8.69 8.69-21.08 8.69-12.38 0-21.07-8.69L641.54-450h-93.85v93.85l161.08 161.07q8.69 8.7 8.69 21.08 0 12.38-8.69 21.08-8.31 8.3-20.89 8.3-12.57 0-21.26-8.3L547.69-269.85V-120q0 12.77-8.61 21.38Q530.46-90 517.69-90q-12.77 0-21.38-8.62-8.62-8.61-8.62-21.38Z" />{" "}
        </SvgIcon>
    );
}
