import { SvgIcon, SxProps } from "@mui/material";
import { Background } from "@xyflow/react";


interface IIconProps{
    sx?: SxProps
}

export default function IconTablet({sx}: IIconProps) {
    return (
        <SvgIcon viewBox="0 -960 960 960" sx={{ ...sx }}>
            <path d="M132.31-180q-29.83 0-51.07-21.24Q60-222.48 60-252.31v-455.38q0-29.83 21.24-51.07Q102.48-780 132.31-780h695.38q29.83 0 51.07 21.24Q900-737.52 900-707.69v455.38q0 29.83-21.24 51.07Q857.52-180 827.69-180H132.31ZM230-240h500v-480H230v480Z" />{" "}
        </SvgIcon>
    );
}
