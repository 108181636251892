//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IAPIResponse } from "@virtus-tech-repository/virtus-tech-repository/lib/models/api.model";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";

const isDev: boolean = process.env.REACT_APP_IS_LOCAL === "true";

// Custom components imports (and hooks and helpers)
export const SceneApi = createApi({
    reducerPath: "scene",
    tagTypes: ["scene", "scenarioScenes"],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_NINJA_ENDPOINT,
    }),
    endpoints: (builder) => ({
        getScenes: builder.query<IScene[], string>({
            query: (scenarioId) => ({
                url: `scenes/${scenarioId}`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IScene) => ({
                              type: "scenarioScenes" as const,
                              id,
                          })),
                          { type: "scenarioScenes", id: "LIST" },
                      ]
                    : [{ type: "scenarioScenes", id: "LIST" }],
        }),
        createScene: builder.mutation<IAPIResponse, { scenarioId: string; scene: IScene }>({
            query: ({ scenarioId, scene }) => ({
                url: process.env.REACT_APP_IS_GLOBAL_ACCELERATOR
                    ? `scene/scenario/${scenarioId}`
                    : `scene/${scenarioId}`,
                method: "POST",
                body: scene,
            }),
            invalidatesTags: [{ type: "scenarioScenes", id: "LIST" }],
        }),
        updateScene: builder.mutation<IAPIResponse, Partial<IScene>>({
            query: (body) => ({
                url: `scene/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: [{ type: "scenarioScenes", id: "LIST" }],
        }),
        reorderScenes: builder.mutation<IAPIResponse, Partial<{ scenarioId: string; sceneIds: string[] }>>({
            query: (body) => ({
                url: `scene/order/${body.scenarioId}`,
                method: "POST",
                body: body.sceneIds,
            }),
            invalidatesTags: [{ type: "scenarioScenes", id: "LIST" }],
        }),

        // TODO: this mutation is in progress, research whether this approach is worth it or not
        updateScenesOrder: builder.mutation<number, string>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const scenes = fetchWithBQ(_arg);
                // console.log("scenes update scenes order", scenes);
                // console.log("")
                // const scenes = await fetchWithBQ(_arg);

                //   for (const scene of scenes) {
                //     await baseQuery({
                //       url: `access-level-requests/${accessLevelRequest.id}/`,
                //       method: 'PUT',
                //       body: accessLevelRequest,
                //     });
                //   }
                //   return { data: accessLevelRequests.length };
                return { data: 10 };
            },
            invalidatesTags: [{ type: "scenarioScenes", id: "LIST" }],
        }),
        // getRandomUserPosts: builder.query<any, void>({
        //     async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        //       // get a random user
        //     //   const randomResult = await fetchWithBQ('users/random')
        //     //   if (randomResult.error)
        //     //     return { error: randomResult.error as FetchBaseQueryError }
        //     //   const user = randomResult.data as User
        //     //   const result = await fetchWithBQ(`user/${user.id}/posts`)
        //     //   return result.data
        //     //     ? { data: result.data as Post }
        //     //     : { error: result.error as FetchBaseQueryError }
        //         _queryApi.
        //         return {data: null}
        //     },
        //   }),
        // updateAllScenes: builder.mutation<IAPIResponse, Partial<IScene[]>>({
        //     // query: (body) => ({
        //     //     url: `scene/${body.id}`,
        //     //     method: "PUT",
        //     //     body,
        //     // }),
        //     // queryFn: () => {
        //     //     return { data: [] };
        //     // },
        //     queryFn: async (arg, api, extraOptions, baseQuery) => {
        //         return {data: []}
        //     },

        //     invalidatesTags: [{ type: "scenarioScenes", id: "LIST" }],
        // }),
        deleteScene: builder.mutation<IAPIResponse, { scenarioId: string; sceneId: string }>({
            query: ({ scenarioId, sceneId }) => ({
                url: process.env.REACT_APP_IS_GLOBAL_ACCELERATOR
                    ? `scene/scenario/${scenarioId}/${sceneId}`
                    : `scene/${scenarioId}/${sceneId}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "scenarioScenes", id: "LIST" }],
        }),
    }),
});

export const {
    useGetScenesQuery,
    useCreateSceneMutation,
    useUpdateSceneMutation,
    useDeleteSceneMutation,
    useUpdateScenesOrderMutation,
    useReorderScenesMutation,
} = SceneApi;
