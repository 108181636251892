import { IconWrapperNames } from "../views/components/IconWrapper";

export function checkIconExistsInIconWrapper(iconName: string | undefined): boolean{
    if(typeof iconName === "undefined"){
        return false;
    }

    // @ts-ignore
    if(IconWrapperNames.includes(iconName)){
        return true
    }else{
        return false
    }
}