import { SvgIcon, SxProps } from "@mui/material";
import { Background } from "@xyflow/react";

interface IIconProps{
    sx?: SxProps
}

export default function IconArrows({sx}: IIconProps) {
    return (
        <SvgIcon viewBox="0 -960 960 960" sx={{ ...sx }}>
            <path d="m296-224-56-56 240-240 240 240-56 56-184-183-184 183Zm0-240-56-56 240-240 240 240-56 56-184-183-184 183Z" />{" "}
        </SvgIcon>
    );
}
