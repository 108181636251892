//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
import { useEffect, useState } from "react";
import { VTButton, VTSidePanel } from "@virtus-tech-repository/virtus-tech-repository";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    setCurrentDragging,
    setCurrentSidePanel,
    setEditHotspotDialogMediaTransfer,
} from "../../store/slices/current.slice";
import VTImmersiveVideoSidePanel from "../components/VTImmersiveVideoSidePanel";
import VTHotspotSidePanel from "../components/VTHotspotSidePanel";
import VTCreatorTabs from "../components/VTCreatorTabs";
import VTPanel from "../components/VTPanel";
import VTImmersiveImageSidePanel from "../components/VTImmersiveSidePanel";
import VTVideoSidePanel from "../components/VTVideoSidePanel";
import VTImageSidePanel from "../components/VTImageSidePanel";
import { VTAudioSidePanel } from "../components/VTAudioSidePanel";
import { Paper, useMediaQuery, useTheme } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import VTTemplateSidePanel from "../components/VTTemplateSidePanel";
import VTImmersiveModelsSidePanel from "../components/VTImmersiveModelsSidePanel";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import VTIconSidePanel from "../components/VTIconSidePanel";
import VTTextSidePanel from "../components/VTTextSidePanel";
import VTImmersiveSidePanelWrapper from "../components/VTImmersiveSidePanelWrapper";
import { useGetAllAudioQuery, useGetAllImagesQuery } from "../../services/media.service";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
    setVideoCurrentState: (videoCurrentState: "Paused" | "Playing" | "Question" | undefined) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function CreatorTabsPanelContainer({ videoCurrentState, setVideoCurrentState }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useAppDispatch();
    const theme = useTheme();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const {
        currentSidePanel,
        editHotspotDialogIsCurrentlyOpen,
        editHotspotDialogTabCurrentlyOpen,
        currentHotspot,
        currentlyPreview,
        currentSidePannelDesiredWidth,
    } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);

    const matches = useMediaQuery("(min-width:1200px)");
    const { data: audios, isLoading: audiosLoading } = useGetAllAudioQuery(userId);
    const { data: images, isLoading: imagesLoading } = useGetAllImagesQuery(userId);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (editHotspotDialogTabCurrentlyOpen === "content" && currentSidePanel.currentTab) {
            setOpenDraw("Images");
        }
    }, [editHotspotDialogTabCurrentlyOpen]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Function to handle closing the draw via tab button
    function setCloseDraw() {
        dispatch(
            setCurrentSidePanel({
                currentTab: undefined,
                currentWidth: 0,
                option: undefined,
            }),
        );
    }

    // Function to handle opening the draw via tab button
    function setOpenDraw(drawName: string) {
        dispatch(
            setCurrentSidePanel({
                currentTab: drawName,
                currentWidth: currentSidePannelDesiredWidth,
                option: undefined,
            }),
        );
    }

    // Hook to hold the current drag item data
    function handleDragStart(mediaType: EDraggableOptions, src: string, preview?: string | undefined) {
        // console.log("****************** dragging start", mediaType);
        // console.log("meida type", mediaType);
        // console.log("src", src);
        // console.log("preview", preview);

        dispatch(
            setCurrentDragging({
                mediaType: mediaType,
                src: src,
                preview: preview,
            }),
        );
    }

    // Hook to clear the current drag item data
    function handleDragStop() {
        dispatch(setCurrentDragging(undefined));
    }

    // function handleEditPanelDoubleClick(Drag: IDragOptions, quesiton?: any) {
    //     dispatch(setEditHotspotDialogMediaTransfer({ src: Drag.src, type: Drag.mediaType }));
    // }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show available images

    return (
        <>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Tab sections */}
            {!currentlyPreview && (
                <VTCreatorTabs
                    currentEditHotSpotDialogTabOpen={editHotspotDialogTabCurrentlyOpen}
                    isEditHotSpotDialogOpen={editHotspotDialogIsCurrentlyOpen}
                    setCloseDraw={setCloseDraw}
                    setOpenDraw={setOpenDraw}
                    disabled={["template", "video"]}
                />
            )}

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Side panel with media content */}

            {!currentlyPreview && (
                <Paper
                    sx={{
                        position: "absolute",
                        top: "68px",
                        left: "80px",
                        bottom: "0px",
                        width: currentSidePanel.currentWidth,
                        zIndex: 1440,
                        borderRadius: 0,
                        // TODO: Not sure why the background was changing color??
                        // backgroundColor: !currentSidePanel.currentWidth ? "#171717" : "#444547",
                        backgroundColor: theme.palette.background.paper,
                        // transition: "0.1s",
                    }}
                >
                    {currentSidePanel.currentWidth !== 0 && (
                        <VTButton
                            type={"icon"}
                            toolTipOptions={{
                                toolTipPlacement: "top-end",
                                toolTipText: "Collapse",
                            }}
                            sx={{
                                position: "absolute",
                                left: currentSidePanel.currentWidth,
                                top: currentHotspot.currentHotspot ? "80px" : "5.5px",
                                width: "30px",
                                height: "30px",
                                borderRadius: "0px 15px 15px 0px",
                                zIndex: 10,
                                backgroundColor: "#444547",
                                fontSize: "0.3em",
                                ":hover": {
                                    cursor: "pointer",
                                    backgroundColor: "#646567",
                                },
                            }}
                            onClick={() =>
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: undefined,
                                        currentWidth: 0,
                                        option: undefined,
                                    }),
                                )
                            }
                        >
                            <CloseRoundedIcon sx={{ fontSize: "3.5em", marginLeft: "-4px" }} />
                        </VTButton>
                    )}
                    {/*-------------------------------------------------------------------------------------*/}
                    {/* Template panel */}
                    {currentSidePanel.currentTab === "Templates" && (
                        <VTTemplateSidePanel
                            handleDragStart={handleDragStart}
                            handleDragStop={handleDragStop}
                            videoCurrentState={videoCurrentState}
                        />
                    )}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* Hotspot panel */}
                    {currentSidePanel.currentTab === "Hotspots" && (
                        <VTHotspotSidePanel
                            sideDrawWidth={currentSidePanel.currentWidth}
                            handleDragStart={handleDragStart}
                            handleDragStop={handleDragStop}
                            videoCurrentState={videoCurrentState}
                        />
                    )}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* Hotspot panel */}
                    {currentSidePanel.currentTab === "Text" && (
                        <VTTextSidePanel
                            
                            sideDrawWidth={currentSidePanel.currentWidth}
                            handleDragStart={handleDragStart}
                            handleDragStop={handleDragStop}
                            videoCurrentState={videoCurrentState}
                        />
                    )}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* 360 images for scene creation */}
                    {currentSidePanel.currentTab === "360 Images" && (
                        <VTImmersiveImageSidePanel
                            sideDrawWidth={currentSidePanel.currentWidth}
                            handleDragStart={handleDragStart}
                            handleDragStop={handleDragStop}
                        />
                    )}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* Image panel as hotspot content */}
                    {/* TODO; Uncomment */}
                    {currentSidePanel.currentTab === "Images" && (
                        <VTImageSidePanel
                            sideDrawWidth={currentSidePanel.currentWidth}
                            handleDragStart={handleDragStart}
                            handleDragStop={handleDragStop}
                            imagesTitle={currentSidePanel.option ? currentSidePanel.option : ""}
                            videoCurrentState={videoCurrentState}
                            images={images}
                            imagesLoading={imagesLoading}
                        />
                    )}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* 360 videos for scene creation */}
                    {currentSidePanel.currentTab === "360 Videos" && (
                        <VTImmersiveVideoSidePanel
                            sideDrawWidth={currentSidePanel.currentWidth}
                            handleDragStart={handleDragStart}
                            handleDragStop={handleDragStop}
                        />
                    )}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* videos for media input */}
                    {currentSidePanel.currentTab === "Videos" && (
                        <VTVideoSidePanel
                            sideDrawWidth={currentSidePanel.currentWidth}
                            handleDragStart={handleDragStart}
                            handleDragStop={handleDragStop}
                            videoCurrentState={videoCurrentState}
                        />
                    )}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* 3D models as hotspot content */}
                    {currentSidePanel.currentTab === "3D" && (
                        <VTImmersiveModelsSidePanel
                            sideDrawWidth={currentSidePanel.currentWidth}
                            handleDragStart={handleDragStart}
                            handleDragStop={handleDragStop}
                            imagesTitle={currentSidePanel.option ? currentSidePanel.option : ""}
                            videoCurrentState={videoCurrentState}
                        />
                    )}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* Audio content */}
                    {currentSidePanel.currentTab === "Audio" && (
                        <VTAudioSidePanel
                            onHandleDragStart={handleDragStart}
                            onHandleDragStop={handleDragStop}
                            videoCurrentState={videoCurrentState}
                            audios={audios}
                            audiosLoading={audiosLoading}
                        />
                    )}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* Image panel as hotspot content */}
                    {/* TODO; Uncomment */}
                    {currentSidePanel.currentTab === "Icons" && (
                        <VTIconSidePanel
                            sideDrawWidth={currentSidePanel.currentWidth}
                            handleDragStart={handleDragStart}
                            handleDragStop={handleDragStop}
                            imagesTitle={currentSidePanel.option ? currentSidePanel.option : ""}
                            videoCurrentState={videoCurrentState}
                        />
                    )}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* 360 media for scene creation */}
                    {currentSidePanel.currentTab === "360 Media" && <VTImmersiveSidePanelWrapper />}
                </Paper>
            )}
        </>
    );
}
