import { useCreateAllImagesMutation, usePreSignedUrlMutation } from "../services/media.service";
import { useAppSelector } from "../store/hooks";
import { useEffect, useState } from "react";
import { AppDispatch } from "../store/store";
import { uploadMedia } from "../store/thunks/upload.thunks";
import { EDraggableOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";

export default function useMediaUploader(
    dispatch: AppDispatch,
): [
    mediaId: string,
    mediaUploadLoading: boolean,
    uploadComplete: boolean,
    handleUpload: (id: string, url: string, type: string) => Promise<void>,
] {
    const [mediaId, setMediaId] = useState<string>("");
    const [filetype, setFiletype] = useState<string>("");
    const [mediaUrl, setMediaUrl] = useState<string>("");

    const [mediaUploadLoading, setLoading] = useState<boolean>(false);
    const [uploadComplete, setUploadComplete] = useState<boolean>(false);

    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();
    const [createAllImages] = useCreateAllImagesMutation();

    async function handleUpload(id: string, url: string, type: string) {
        setMediaId(id);
        setFiletype(type.split("/")[1]);
        setMediaUrl(url);

        setUploadComplete(false);
        setLoading(true);

        try {
            await preSignedUrl({
                organisation,
                media_type: EDraggableOptions.IMAGE,
                media_id: id, // Pass the new mediaId
                filetype: type.split("/")[1],
            });
        } catch (e) {
            console.error("Error getting presigned url for image upload");
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log("hook::", mediaId, mediaUrl, filetype);
    }, [mediaId, mediaUrl, filetype]);

    useEffect(() => {
        if (uploadUrl && !preSignedUrlLoading) {
            handleUploadImage();
        }
    }, [uploadUrl, preSignedUrlLoading]);

    async function handleUploadImage() {
        if (uploadUrl) {
            const file = await dataUrlToFile(mediaUrl, "clipboard image");

            try {
                await dispatch(
                    uploadMedia({
                        media: file,
                        url: uploadUrl.url,
                        dispatch: dispatch,
                    }),
                ).unwrap();

                handleAddMediaToUser();
            } catch (e) {
                console.error("Error uploading image");
                setLoading(false);
            }
        }
    }

    async function handleAddMediaToUser() {
        try {
            await createAllImages({
                id: userId,
                media_id: mediaId,
                media_type: EDraggableOptions.IMAGE,
                filetype: filetype,
                name: "clipboard image",
                description: "",
                date: Date.now(),
                tags: [],
                preview: "",
            });

            setLoading(false);
            setUploadComplete(true);
        } catch (e) {
            console.error("Error adding image to user: ", e);
        }
    }

    async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: `image/${filetype}` });
    }

    return [mediaId, mediaUploadLoading, uploadComplete, handleUpload];
}
